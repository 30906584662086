import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-324cf80a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "sidebar-header-menu",
  ref: "sidebarHeaderMenu"
}
const _hoisted_2 = { class: "flex gap-3 items-center mb-6px <laptop:mb-0" }
const _hoisted_3 = {
  key: 1,
  class: "h-42px"
}
const _hoisted_4 = {
  key: 0,
  class: "px-6 py-4"
}
const _hoisted_5 = { class: "mx-4 font-semibold" }
const _hoisted_6 = { class: "mx-4 font-semibold" }
const _hoisted_7 = { class: "flex flex-row flex-nowrap items-center pt-6 pb-2" }
const _hoisted_8 = { class: "font-semibold opacity-55 uppercase text-sm" }
const _hoisted_9 = { class: "mx-4 font-semibold" }
const _hoisted_10 = { class: "mx-4 font-semibold" }
const _hoisted_11 = { class: "mx-4 font-semibold" }
const _hoisted_12 = {
  key: 0,
  class: "sidebar-dropdown"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_burger_menu = _resolveComponent("i-burger-menu")!
  const _component_logo_nav = _resolveComponent("logo-nav")!
  const _component_store_dropdown_select = _resolveComponent("store-dropdown-select")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_click_out = _resolveDirective("click-out")!

  return (_openBlock(), _createElementBlock("aside", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_i_burger_menu, {
          class: "burger-menu-show",
          toggle: _ctx.isShowSidebar,
          onClick: _ctx.showSidebar
        }, null, 8, ["toggle", "onClick"]),
        _createVNode(_component_logo_nav)
      ]),
      (_ctx.getStoreSelected)
        ? (_openBlock(), _createBlock(_component_store_dropdown_select, {
            key: 0,
            class: "store-dropdown-select",
            options: _ctx.storeOptions,
            selected: _ctx.getStoreSelected,
            "extra-option": _ctx.$t('Create Store'),
            onChange: _ctx.storeSelectHandler
          }, null, 8, ["options", "selected", "extra-option", "onChange"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3))
    ], 512),
    _createElementVNode("div", {
      class: _normalizeClass(["sidebar", {'sidebar-show': _ctx.isShowSidebar}])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["sidebar-nav-menu", {'sidebar-nav-menu-show': _ctx.isShowSidebar}]),
        "aria-hidden": "true",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.closeSidebar && _ctx.closeSidebar(...args)))
      }, [
        (_ctx.getStoreSelected)
          ? (_openBlock(), _createElementBlock("nav", _hoisted_4, [
              _createVNode(_component_router_link, {
                class: "sidelink",
                to: { name: 'dashboard', params: { store_id: _ctx.getStoreSelected } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_fa_icon, {
                    class: "w-18px",
                    icon: "chart-column"
                  }),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('Dashboard')), 1)
                ]),
                _: 1
              }, 8, ["to"]),
              _createVNode(_component_router_link, {
                class: _normalizeClass(["sidelink", {'router-link-active': _ctx.storeSettingsActive}]),
                to: { name: 'store-settings', params: { store_id: _ctx.getStoreSelected } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_fa_icon, {
                    class: "w-18px",
                    icon: "gear"
                  }),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('Settings')), 1)
                ]),
                _: 1
              }, 8, ["to", "class"]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('Payments')), 1)
              ]),
              _createVNode(_component_router_link, {
                class: _normalizeClass(["sidelink", {'router-link-active': _ctx.withdrawalsLinkActive}]),
                to: { name: 'wallets-withdrawals', params: { store_id: _ctx.getStoreSelected } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_fa_icon, {
                    class: "w-18px",
                    icon: "wallet"
                  }),
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('Withdrawals')), 1)
                ]),
                _: 1
              }, 8, ["to", "class"]),
              _createVNode(_component_router_link, {
                class: _normalizeClass(["sidelink", {'router-link-active': _ctx.invoicesLinkActive}]),
                to: { name: 'invoices', params: { store_id: _ctx.getStoreSelected } }
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_fa_icon, {
                    class: "w-18px",
                    icon: "file-lines"
                  }),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('Invoices')), 1)
                ]),
                _: 1
              }, 8, ["to", "class"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: "sidebar-account",
          "aria-hidden": "true",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showAccountMenu && _ctx.showAccountMenu(...args)))
        }, [
          _createVNode(_component_router_link, {
            to: "/account",
            class: "sidebar-account-link pointer-events-none"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_fa_icon, {
                class: "w-18px",
                icon: ['far', 'user']
              }),
              _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('Account')), 1)
            ]),
            _: 1
          }),
          (_ctx.isShowAccountMenu)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _withDirectives((_openBlock(), _createElementBlock("ul", {
                  class: "text-gray-700 bg-gray-light text-left border-1",
                  tabindex: "0",
                  "aria-hidden": "true",
                  onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeSidebar && _ctx.closeSidebar(...args)))
                }, [
                  _createElementVNode("li", null, [
                    _createVNode(_component_router_link, {
                      to: "/account",
                      class: "block p-3 hover:text-sky-500 border-b-1 font-semibold"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('Manage Account')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("li", null, [
                    _createElementVNode("span", {
                      class: "block p-3 text-red-400 font-semibold cursor-pointer",
                      "aria-hidden": "true",
                      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logout && _ctx.logout(...args)))
                    }, _toDisplayString(_ctx.$t('Logout')), 1)
                  ])
                ])), [
                  [_directive_click_out, _ctx.closeAccountMenu]
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["backdrop", {'backdrop-show': _ctx.isShowSidebar}]),
      "aria-hidden": "true",
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.closeSidebar && _ctx.closeSidebar(...args)))
    }, null, 2)
  ]))
}