
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
    },
    size: {
      type: String,
      default: '2x',
    },
    color: {
      type: String,
      default: 'text-gray-400',
    },
    bgColor: {
      type: String,
      default: 'loading-bg-blur',
    },
  },
});
