import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e416e0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "bg-white p-6 relative rounded-sm shadow-sm border flex flex-col gap-6"
}
const _hoisted_2 = { class: "font-600 flex items-center gap-2" }
const _hoisted_3 = { class: "opacity-55" }
const _hoisted_4 = { class: "flex flex-col gap-4 justify-between basis-full" }
const _hoisted_5 = { class: "data-title" }
const _hoisted_6 = { class: "break-all flex items-center gap-2" }
const _hoisted_7 = { class: "self-end <desktop:self-start" }
const _hoisted_8 = {
  key: 0,
  class: "flex flex-col gap-6"
}
const _hoisted_9 = { class: "break-all text-gray-500 text-sm font-600" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_skeleton_box = _resolveComponent("ui-skeleton-box")!
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ui_tooltip = _resolveComponent("ui-tooltip")!
  const _component_ui_copy_to_clipboard = _resolveComponent("ui-copy-to-clipboard")!
  const _component_ui_qrcode = _resolveComponent("ui-qrcode")!
  const _component_ui_modal = _resolveComponent("ui-modal")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_ui_skeleton_box, {
        key: 0,
        width: "100%",
        height: _ctx.skeletonHeight
      }, null, 8, ["height"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('Processing wallets')), 1),
          _createVNode(_component_ui_tooltip, {
            text: _ctx.$t('These wallets using for the transfer tokens from processing wallets to user wallets')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_fa_icon, {
                class: "opacity-55 hover:opacity-100",
                icon: ['far', 'circle-question']
              })
            ]),
            _: 1
          }, 8, ["text"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.processingWallets, (wallet, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "flex <desktop:(flex-col items-start) justify-between items-center gap-2"
            }, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(wallet.blockchain), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(wallet.address), 1),
                  _createVNode(_component_ui_copy_to_clipboard, {
                    "text-to-copy": wallet.address
                  }, null, 8, ["text-to-copy"]),
                  _createVNode(_component_fa_icon, {
                    icon: "qrcode",
                    class: "w-18px cursor-pointer opacity-80 hover:opacity-100",
                    onClick: ($event: any) => (_ctx.showModal(wallet.address, wallet.blockchain))
                  }, null, 8, ["onClick"])
                ])
              ]),
              _createElementVNode("div", _hoisted_7, _toDisplayString(wallet.balance) + " " + _toDisplayString(_ctx.nativeToken(wallet.blockchain)), 1)
            ]))
          }), 128))
        ]),
        _createVNode(_component_ui_modal, {
          modelValue: _ctx.show,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event))
        }, {
          default: _withCtx(() => [
            (_ctx.currentAddress)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.currentAddress), 1),
                  _createVNode(_component_ui_qrcode, {
                    value: _ctx.currentAddress,
                    size: 256,
                    img: `${_ctx.currenBlockchain}.svg`
                  }, null, 8, ["value", "img"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]))
}