
import { defineComponent } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import UiSkeletonBox from '@/components/ui/UiSkeletonBox.vue';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';
import UiQrcode from '@/components/ui/UiQrcode.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';

export default defineComponent({
  components: {
    UiSkeletonBox,
    UiCopyToClipboard,
    UiModal,
    UiQrcode,
    UiTooltip,
  },

  props: {
    storeSelected: {
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      windowWidth: window.innerWidth,
      show: false,
      currentAddress: '',
      currenBlockchain: '',
    };
  },

  computed: {
    skeletonHeight(): string {
      if (this.windowWidth <= 768) {
        return '290px';
      }
      return '335px';
    },

    ...mapState('dashboard', {
      processingWallets: 'processingWallets',
      isProcessingWalletsLoaded: 'isProcessingWalletsLoaded',
    }),
    ...mapGetters('dictionaries', {
      nativeToken: 'nativeToken',
    }),
  },

  watch: {
    storeSelected() {
      this.loading = true;
      this.loadProcessingWalletsData();
    },
  },

  created() {
    if (!this.isProcessingWalletsLoaded) {
      this.loading = true;
    }
    this.loadProcessingWalletsData();
  },

  methods: {
    showModal(address: string, blockchain: string) {
      this.show = true;
      this.currentAddress = address;
      this.currenBlockchain = blockchain;
    },

    async loadProcessingWalletsData() {
      try {
        await this.loadProcessingWallets();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    ...mapActions('dashboard', ['loadProcessingWallets']),
  },
});
