import { AxiosBaseResponse, Token } from '@/api/types';
import { api } from '@/api/Api';
import {
  ChangeAccountPasswordRequest,
  ChangeAccountPasswordResponse,
  GetAccountResponse,
  OTPDisableRequest,
  OTPEnableRequest,
} from '@/services/AccountService/types';

const urls = {
  changePassword: '/users/user/change-password',
  getAccount: '/users/user',
  otpEnable: '/users/user/otp/enable',
  otpDisable: '/users/user/otp/disable',
};

export default class AccountService {
  public static async changePassword(payload: ChangeAccountPasswordRequest, token: Token): Promise<AxiosBaseResponse<ChangeAccountPasswordResponse>> {
    return api.patch(urls.changePassword, payload, token);
  }

  public static async getAccount(token: Token): Promise<AxiosBaseResponse<GetAccountResponse>> {
    return api.get(urls.getAccount, null, token);
  }

  public static async enableOtp(payload: OTPEnableRequest, token: Token): Promise<AxiosBaseResponse<void>> {
    return api.post(urls.otpEnable, payload, token);
  }

  public static async disableOtp(payload: OTPDisableRequest, token: Token): Promise<AxiosBaseResponse<void>> {
    return api.post(urls.otpDisable, payload, token);
  }
}
