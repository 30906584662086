import { Context } from '@/router/types';

export default function guest({ next, store }: Context) {
  if (store.getters['auth/isAuth']) {
    if (store.getters['stores/getStoreSelected']) {
      return next({
        name: 'dashboard',
        params: { store_id: store.getters['stores/getStoreSelected'] },
      });
    }
    return next({ name: 'store-create' });
  }
  return next();
}
