export function dateFormat(date: string): string {
  const dateString = new Date(date);
  const padL = (nr: number, chr = '0') => `${nr}`.padStart(2, chr);

  return `${
    padL(dateString.getMonth() + 1)}/${
    padL(dateString.getDate())}/${
    dateString.getFullYear()} ${
    padL(dateString.getHours())}:${
    padL(dateString.getMinutes())}:${
    padL(dateString.getSeconds())}`;
}
