
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import UiSwitcher from '@/components/ui/UiSwitcher.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';

export default defineComponent({
  components: {
    UiSwitcher,
    UiLoading,
    UiCopyToClipboard,
  },

  data() {
    return {
      checked: false,
      tableColumns: [
        {
          label: 'Enabled',
          field: 'enabled',
          textAlign: 'text-left',
        },
        {
          label: 'Api Key',
          field: 'apikey',
          textAlign: 'text-left',
        },
        {
          label: 'Actions',
          field: 'actions',
          textAlign: 'text-right',
        },
      ],
      loading: false,
    };
  },

  computed: {
    ...mapState('stores', ['apiKeyList']),
  },

  created() {
    this.loadApiKeys();
  },

  methods: {
    async createApiKeyHandler() {
      this.loading = true;
      try {
        await this.createApiKey();
        await this.loadApiKeys();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    async switchHandler(apiKeyId: string, enabled: boolean) {
      this.loading = true;
      try {
        await this.updateApiKey({
          apiKeyId,
          payload: {
            enabled: !enabled,
          },
        });
        await this.loadApiKeys();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    async deleteApiKeyHandler(apiKeyId: string) {
      this.loading = true;
      try {
        await this.deleteApiKey(apiKeyId);
        await this.loadApiKeys();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    ...mapActions('stores', [
      'createApiKey',
      'loadApiKeys',
      'updateApiKey',
      'deleteApiKey',
    ]),
  },
});
