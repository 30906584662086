
import { defineComponent } from 'vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import UiSwitcher from '@/components/ui/UiSwitcher.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiExpand from '@/components/ui/UiExpand.vue';

export default defineComponent({
  components: {
    UiSwitcher,
    UiSelect,
    UiButtonLoading,
    UiExpand,
  },

  data() {
    return {
      address: '',
      enabledWithdrawals: false,
      minBalance: '',
      intervalSelected: 0,
      buttonLoading: false,
    };
  },

  computed: {
    walletId() {
      return this.$route.params.wallet_id;
    },

    ...mapState('wallets', {
      withdrawalsSettings: 'withdrawalsSettings',
    }),
    ...mapGetters('dictionaries', {
      withdrawalIntervals: 'withdrawalIntervals',
    }),
  },

  watch: {
    withdrawalsSettings() {
      this.syncDataFromVuex();
    },
  },

  created() {
    this.loadWithdrawalsSettings(this.walletId);
    this.syncDataFromVuex();
  },

  unmounted() {
    this.clearWithdrawalsSettings();
  },

  methods: {
    async updateWithdrawalsHandler() {
      this.buttonLoading = true;
      try {
        await this.updateWithdrawalsSettings({
          walletId: this.walletId,
          payload: {
            address: this.address,
            enabled: this.enabledWithdrawals,
            minBalance: Number(this.minBalance),
            interval: Number(this.intervalSelected),
          },
        });
        await this.loadWithdrawalsSettings(this.walletId);
        this.buttonLoading = false;
        this.$toast.success(this.$t('Withdrawals settings successfully updated'));
        this.$router.push({ name: 'wallets-withdrawals' });
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    switchHandler() {
      this.enabledWithdrawals = !this.enabledWithdrawals;
    },

    syncDataFromVuex() {
      this.address = this.withdrawalsSettings.address;
      this.enabledWithdrawals = this.withdrawalsSettings.enabled;
      this.minBalance = this.withdrawalsSettings.minBalance;
      this.intervalSelected = this.withdrawalsSettings.interval;
    },

    ...mapMutations('wallets', ['clearWithdrawalsSettings']),
    ...mapActions('wallets', ['loadWithdrawalsSettings', 'updateWithdrawalsSettings']),
  },
});
