import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-306337a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "select-wrapper" }
const _hoisted_2 = ["id", "value"]
const _hoisted_3 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("select", {
      class: "select",
      id: _ctx.id,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectHandler && _ctx.selectHandler(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, i) => {
        return (_openBlock(), _createElementBlock("option", {
          key: i,
          value: option.value
        }, _toDisplayString(option.title), 9, _hoisted_3))
      }), 128))
    ], 40, _hoisted_2),
    _createVNode(_component_fa_icon, {
      icon: "angle-down",
      class: "angle-down"
    })
  ]))
}