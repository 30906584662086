import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["loading", _ctx.bgColor])
      }, [
        _createVNode(_component_fa_icon, {
          icon: "spinner",
          size: _ctx.size,
          class: _normalizeClass(["animate-spin text-gray-400", _ctx.color])
        }, null, 8, ["size", "class"])
      ], 2))
    : _createCommentVNode("", true)
}