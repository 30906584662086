
import { defineComponent } from 'vue';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';

export default defineComponent({
  components: {
    UiCopyToClipboard,
  },

  data() {
    return {
      tableColumns: [
        {
          label: 'Address',
          field: 'address',
          textAlign: 'text-left',
        },
        {
          label: 'Blockchain',
          field: 'blockchain',
          textAlign: 'text-center',
        },
        {
          label: 'Available Balance for withdrawal',
          field: 'balance',
          textAlign: 'text-center',
        },
        {
          label: 'Actions',
          field: 'actions',
          textAlign: 'text-right',
        },
      ],
    };
  },

  computed: {
    ...mapGetters('stores', {
      getStoreSelected: 'getStoreSelected',
    }),
    ...mapState('wallets', {
      wallets: 'wallets',
      isWalletsLoading: 'isWalletsLoading',
    }),
  },

  created() {
    if (!this.isWalletsLoading) {
      this.loadWallets();
      this.setShouldWalletsBeLoaded(false);
    }
  },

  unmounted() {
    this.setIsWalletsLoading(false);
  },

  methods: {
    ...mapActions('wallets', ['loadWallets', 'withdrawalsRequest']),
    ...mapMutations('wallets', ['setIsWalletsLoading', 'setShouldWalletsBeLoaded']),
  },
});
