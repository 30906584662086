import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-2xl font-600" }
const _hoisted_2 = { class: "flex flex-col gap-2" }
const _hoisted_3 = {
  for: "currencyTypes",
  class: "self-start"
}
const _hoisted_4 = { class: "flex items-center gap-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!

  return (_openBlock(), _createElementBlock("form", {
    class: "flex flex-col gap-8 w-340px",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.withdrawalsRequestHandler && _ctx.withdrawalsRequestHandler(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('Request a withdrawal')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Select a currrency')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ui_select, {
          id: "currencyTypes",
          class: "max-w-200px h-42px",
          modelValue: _ctx.selectedCurrencyOption,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedCurrencyOption) = $event)),
          options: _ctx.currenciesOptions
        }, null, 8, ["modelValue", "options"]),
        _createElementVNode("div", null, _toDisplayString(_ctx.walletBalance(_ctx.selectedCurrencyId)), 1)
      ])
    ]),
    _createVNode(_component_ui_button_loading, {
      class: "button button-primary py-2 px-4 w-full",
      type: "submit",
      loading: _ctx.buttonLoading
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('Submit')), 1)
      ]),
      _: 1
    }, 8, ["loading"])
  ], 32))
}