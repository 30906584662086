import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { useToast } from 'vue-toastification';
import { RootState } from '@/store/types';
import DashboardService from '@/services/DashboardService';
import {
  BalancesResponse,
  HistoryResponse,
  RecentTxsResponse,
  RecentInvoicesResponse,
  ProcessingWalletsResponse,
} from '@/services/DashboardService/types';
import { dateFormat, decimal, gte } from '@/utils';

const toast = useToast();

interface BalanceDataRecord {
  x: string | number | Date;
  y: string;
}

interface DashboardState {
  balances: BalanceDataRecord[];
  history: Partial<HistoryResponse>;
  recentTransactions: RecentTxsResponse[];
  isRecentTransactionsLoaded: boolean;
  recentInvoices: RecentInvoicesResponse[];
  isRecentInvoicesLoaded: boolean;
  processingWallets: ProcessingWalletsResponse[];
  isProcessingWalletsLoaded: boolean;
}

const state: DashboardState = {
  balances: [],
  history: {},
  recentTransactions: [],
  isRecentTransactionsLoaded: false,
  recentInvoices: [],
  isRecentInvoicesLoaded: false,
  processingWallets: [],
  isProcessingWalletsLoaded: false,
};

const getters: GetterTree<DashboardState, RootState> = {
  processingWallets(state) {
    return state.processingWallets.map((item) => {
      return {
        ...item,
        isEnoughBalance: gte(item.balance, item.minBalance),
      };
    });
  },
};

const mutations: MutationTree<DashboardState> = {
  setBalances(state, value: BalancesResponse[]) {
    state.balances = [...value]
      .sort(DashboardService.dateSort)
      .map((item) => {
        return {
          x: new Date(Number(item.date) * 1000),
          y: decimal(item.amount),
        };
      });
  },

  setHistory(state, value: HistoryResponse) {
    state.history = value;
  },

  setRecentTransactions(state, value: RecentTxsResponse[]) {
    state.recentTransactions = [...value]
      .map((item) => {
        return {
          ...item,
          date: dateFormat(item.date),
          amount: decimal(item.amount),
          currencyId: `${item.currencyId.split('.')[0]}
            (${item.currencyId.split('.')[1]})`,
        };
      });
  },

  setIsRecentTransactionsLoaded(state, value: boolean) {
    state.isRecentTransactionsLoaded = value;
  },

  setRecentInvoices(state, value: RecentInvoicesResponse[]) {
    state.recentInvoices = [...value]
      .map((item) => {
        return {
          ...item,
          date: dateFormat(item.createdAt),
        };
      });
  },

  setIsRecentInvoicesLoaded(state, value: boolean) {
    state.isRecentInvoicesLoaded = value;
  },

  setProcessingWallets(state, value: ProcessingWalletsResponse[]) {
    state.processingWallets = [...value]
      .map((item) => {
        return {
          ...item,
          balance: decimal(item.balance),
        };
      });
  },

  setIsProcessingWalletsLoaded(state, value: boolean) {
    state.isProcessingWalletsLoaded = value;
  },
};

const actions: ActionTree<DashboardState, RootState> = {
  async loadBalances(context, params) {
    try {
      const { data } = await DashboardService.getBalances(
        context.rootGetters['stores/getStoreSelected'],
        params,
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('setBalances', result);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async loadHistory(context) {
    try {
      const { data } = await DashboardService.getHistory(
        context.rootGetters['stores/getStoreSelected'],
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('setHistory', result);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async loadRecentTransactions(context) {
    try {
      const { data } = await DashboardService.getRecentTransactions(
        context.rootGetters['stores/getStoreSelected'],
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('setRecentTransactions', result);
      context.commit('setIsRecentTransactionsLoaded', true);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async loadRecentInvoices(context) {
    try {
      const { data } = await DashboardService.getRecentInvoices(
        context.rootGetters['stores/getStoreSelected'],
        { page: 1, perPage: 5, sortField: 'created_at', sortDirection: 'desc' },
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('setRecentInvoices', result);
      context.commit('setIsRecentInvoicesLoaded', true);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async loadProcessingWallets(context) {
    try {
      const { data } = await DashboardService.getProcessingWallets(
        context.rootGetters['stores/getStoreSelected'],
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('setProcessingWallets', result);
      context.commit('setIsProcessingWalletsLoaded', true);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },
};

export const dashboard: Module<DashboardState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
