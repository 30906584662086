
import { defineComponent } from 'vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';
import UiSkeletonBox from '@/components/ui/UiSkeletonBox.vue';

export default defineComponent({
  components: {
    UiCopyToClipboard,
    UiSkeletonBox,
  },

  data() {
    return {
      invoiceSummaryColumns: [
        {
          label: 'Payment Method',
          field: 'currency',
          textAlign: 'text-left',
        },
        {
          label: 'Address',
          field: 'address',
          textAlign: 'text-left',
        },
        {
          label: 'Rate',
          field: 'rate',
          textAlign: 'text-left',
        },
        {
          label: 'Paid',
          field: 'balance',
          textAlign: 'text-left',
        },
      ],
      invoicetransactionsColumns: [
        {
          label: 'Payment Method',
          field: 'currency',
          textAlign: 'text-left',
        },
        {
          label: 'Date',
          field: 'createdAt',
          textAlign: 'text-left',
        },
        {
          label: 'Transaction Hash',
          field: 'tx',
          textAlign: 'text-left',
        },
        {
          label: 'Sender',
          field: 'sender',
          textAlign: 'text-left',
        },
        {
          label: 'Amount',
          field: 'amount',
          textAlign: 'text-left',
        },
      ],
      loading: true,
    };
  },

  computed: {
    invoiceId() {
      return this.$route.params.invoice_id;
    },

    storeId() {
      return this.$route.params.store_id;
    },

    ...mapState('invoices', {
      invoiceDetails: 'invoiceDetails',
      invoiceDetailsAddresses: 'invoiceDetailsAddresses',
      invoiceDetailsTransactions: 'invoiceDetailsTransactions',
    }),
  },

  created() {
    this.loadInvoicesDetailsData();
  },

  unmounted() {
    this.clearInvoiceDetailsData();
  },

  methods: {
    async loadInvoicesDetailsData() {
      try {
        await this.loadInvoiceDetails(this.invoiceId);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    ...mapMutations('invoices', ['clearInvoiceDetailsData']),
    ...mapActions('invoices', ['loadInvoiceDetails']),
  },
});
