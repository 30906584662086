
import { defineComponent } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

export default defineComponent({
  components: {
    UiSelect,
    UiButtonLoading,
  },

  data() {
    return {
      storeName: '',
      currencySelect: '',
      rateSourceSelect: '',
      buttonLoading: false,
    };
  },

  computed: {
    ...mapState('dictionaries', {
      rateSources: 'rateSources',
    }),
    ...mapGetters('dictionaries', {
      fiatCurrencies: 'fiatCurrencies',
    }),
  },

  watch: {
    fiatCurrencies() {
      this.syncDataFromVuex();
    },
  },

  mounted() {
    this.syncDataFromVuex();
  },

  methods: {
    async createStoreHandler() {
      try {
        this.buttonLoading = true;
        await this.createStore({
          name: this.storeName,
          currency: this.currencySelect,
          rateSource: this.rateSourceSelect,
        });
        this.buttonLoading = false;
        this.$toast.success(this.$t('Store successfully created'));
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    syncDataFromVuex() {
      this.currencySelect = this.fiatCurrencies[0]?.value;
      this.rateSourceSelect = this.rateSources[0]?.value;
    },

    ...mapActions('stores', ['createStore']),
  },
});
