
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    current: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 10,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
  },

  data() {
    return {
      input: '',
    };
  },

  computed: {
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.endPage; i++) {
        range.push(i);
      }
      return range;
    },

    startPage() {
      if (this.current === 1) {
        return 1;
      }
      if (this.current === this.totalPages) {
        const start = this.totalPages - this.maxVisibleButtons + 1;
        return (start > 0) ? start : 1;
      }
      return this.current - 1;
    },

    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
    },

    totalPages() {
      return Math.ceil(this.total / this.perPage);
    },

    nextPage() {
      return this.current + 1;
    },

    prevPage() {
      return this.current - 1;
    },

    firstElem() {
      return this.pages[0];
    },

    lastElem() {
      return this.pages[this.pages.length - 1];
    },

    hasFirst() {
      const start = this.firstElem - 1;
      return ((start > 0) ? start : 1) !== 1;
    },

    hasLast() {
      return this.endPage + 1 < this.totalPages;
    },
  },

  methods: {
    changePage(page: number) {
      if (page > 0 && page <= this.totalPages) {
        this.$emit('page-change', page);
      }
    },
  },
});
