import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f5d6815"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-2xl font-600 mb-6" }
const _hoisted_2 = { class: "form-inputs" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = {
  for: "storeId",
  class: "self-start"
}
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = {
  for: "storeName",
  class: "self-start"
}
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = {
  for: "storeWebsite",
  class: "self-start"
}
const _hoisted_9 = { class: "text-2xl font-600 mb-6" }
const _hoisted_10 = { class: "payment" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = {
  for: "currencySelect",
  class: "self-start"
}
const _hoisted_13 = { class: "form-group" }
const _hoisted_14 = {
  for: "invoiceExpires",
  class: "self-start"
}
const _hoisted_15 = { class: "flex max-w-180px items-center" }
const _hoisted_16 = { class: "text-center p-2 border bg-gray-200 rounded-r-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('General')), 1),
    _createElementVNode("form", {
      class: "form",
      onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateGeneralSettings && _ctx.updateGeneralSettings(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('Store ID')), 1),
          _withDirectives(_createElementVNode("input", {
            disabled: "",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.storeId) = $event)),
            id: "storeId",
            class: "input",
            type: "text"
          }, null, 512), [
            [_vModelText, _ctx.storeId]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('Store Name')), 1),
          _withDirectives(_createElementVNode("input", {
            required: "",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.storeName) = $event)),
            id: "storeName",
            class: "input",
            type: "text"
          }, null, 512), [
            [_vModelText, _ctx.storeName]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('Store Website')), 1),
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.storeWebsite) = $event)),
            id: "storeWebsite",
            class: "input",
            type: "text"
          }, null, 512), [
            [_vModelText, _ctx.storeWebsite]
          ])
        ])
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('Payment')), 1),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('Default currency')), 1),
            (_ctx.fiatCurrencies.length)
              ? (_openBlock(), _createBlock(_component_ui_select, {
                  key: 0,
                  id: "currencySelect",
                  class: "max-w-90px h-42px",
                  modelValue: _ctx.storeCurrencySelect,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.storeCurrencySelect) = $event)),
                  options: _ctx.fiatCurrencies
                }, null, 8, ["modelValue", "options"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('Invoice expires if the full amount has not been paid after')), 1),
            _createElementVNode("div", _hoisted_15, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.invoiceExpires) = $event)),
                id: "invoiceExpires",
                class: "input w-1/2 relative z-1",
                type: "number"
              }, null, 512), [
                [_vModelText, _ctx.invoiceExpires]
              ]),
              _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('minutes')), 1)
            ])
          ])
        ])
      ]),
      _createVNode(_component_ui_button_loading, {
        class: "button button-primary py-2 px-4 self-start",
        type: "submit",
        loading: _ctx.buttonLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Save')), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ], 32)
  ]))
}