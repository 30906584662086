
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import UiSkeletonBox from '@/components/ui/UiSkeletonBox.vue';

export default defineComponent({
  props: {
    storeSelected: {
      type: String,
    },
  },

  components: {
    UiSkeletonBox,
  },

  data() {
    return {
      loading: false,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    skeletonHeight(): string {
      if (this.windowWidth <= 768) {
        return '210px';
      }
      return '122px';
    },

    ...mapState('dashboard', {
      history: 'history',
    }),
  },

  watch: {
    storeSelected() {
      this.loading = true;
      this.loadHistoryData();
    },
  },

  created() {
    if (!Object.keys(this.history).length) {
      this.loading = true;
    }
    this.loadHistoryData();
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    async loadHistoryData() {
      try {
        await this.loadHistory();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    ...mapActions('dashboard', ['loadHistory']),
  },
});
