
import { defineComponent } from 'vue';
import QrcodeVue from 'qrcode.vue';

export default defineComponent({
  components: {
    QrcodeVue,
  },

  props: {
    value: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 100,
    },
    img: {
      type: String,
    },
  },

  methods: {
    resolveImgUrl(path: string) {
      const images = require.context('@/assets/img/', false, /\.png$|\.jpg$|\.svg$/);
      return images(`./${path}`);
    },
  },
});
