import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b08c0db"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col gap-8" }
const _hoisted_2 = { class: "flex items-center justify-between gap-2 <desktop:(flex-col items-start)" }
const _hoisted_3 = { class: "invoice-title" }
const _hoisted_4 = { class: "flex gap-6" }
const _hoisted_5 = { class: "invoice-information" }
const _hoisted_6 = { class: "font-600 text-2xl" }
const _hoisted_7 = {
  key: 0,
  class: "w-full my-4"
}
const _hoisted_8 = { class: "py-2" }
const _hoisted_9 = {
  key: 1,
  class: "w-full my-4"
}
const _hoisted_10 = { class: "border-b" }
const _hoisted_11 = { class: "text-left py-2 pr-3" }
const _hoisted_12 = { class: "py-2" }
const _hoisted_13 = { class: "border-b" }
const _hoisted_14 = { class: "text-left py-2 pr-4" }
const _hoisted_15 = { class: "py-2" }
const _hoisted_16 = { class: "border-b" }
const _hoisted_17 = { class: "text-left py-2 pr-4" }
const _hoisted_18 = { class: "py-2" }
const _hoisted_19 = { class: "border-b" }
const _hoisted_20 = { class: "text-left py-2 pr-4" }
const _hoisted_21 = { class: "py-2" }
const _hoisted_22 = { class: "border-b" }
const _hoisted_23 = { class: "text-left py-2 pr-4" }
const _hoisted_24 = { class: "py-2" }
const _hoisted_25 = { class: "border-b" }
const _hoisted_26 = { class: "text-left py-2 pr-4" }
const _hoisted_27 = { class: "py-2" }
const _hoisted_28 = { class: "border-b" }
const _hoisted_29 = { class: "text-left py-2 pr-4" }
const _hoisted_30 = { class: "py-2" }
const _hoisted_31 = { class: "border-b" }
const _hoisted_32 = { class: "text-left py-2 pr-4" }
const _hoisted_33 = { class: "py-2" }
const _hoisted_34 = { class: "text-left py-2 pr-4" }
const _hoisted_35 = { class: "py-2" }
const _hoisted_36 = { class: "invoice-summary" }
const _hoisted_37 = { class: "font-600 text-2xl" }
const _hoisted_38 = {
  key: 0,
  class: "w-full my-4"
}
const _hoisted_39 = { class: "py-2" }
const _hoisted_40 = {
  key: 1,
  class: "w-full my-4"
}
const _hoisted_41 = { class: "border-b" }
const _hoisted_42 = { key: 0 }
const _hoisted_43 = {
  key: 0,
  class: "break-all flex items-center gap-2"
}
const _hoisted_44 = { key: 1 }
const _hoisted_45 = { class: "transactions" }
const _hoisted_46 = { class: "font-600 text-2xl" }
const _hoisted_47 = {
  key: 0,
  class: "w-full my-4"
}
const _hoisted_48 = { class: "py-2" }
const _hoisted_49 = {
  key: 1,
  class: "w-full my-4"
}
const _hoisted_50 = { class: "border-b" }
const _hoisted_51 = {
  key: 0,
  class: "break-all flex items-center gap-2"
}
const _hoisted_52 = {
  key: 1,
  class: "break-all flex items-center gap-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ui_skeleton_box = _resolveComponent("ui-skeleton-box")!
  const _component_ui_copy_to_clipboard = _resolveComponent("ui-copy-to-clipboard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('Invoice')), 1),
          _createElementVNode("span", null, _toDisplayString(_ctx.invoiceId), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_ctx.invoiceDetails.status === 'waiting'
            || _ctx.invoiceDetails.status === 'partially_paid')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'invoice', params: { invoice_id: _ctx.invoiceId } },
                class: "button button-primary px-6 py-3 flex items-center gap-2"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('Checkout')), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true),
          _createVNode(_component_router_link, {
            to: { name: 'invoices', params: { store_id: _ctx.storeId } },
            class: "button button-primary px-6 py-3 flex items-center gap-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Back')), 1)
            ]),
            _: 1
          }, 8, ["to"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('Invoice Information')), 1),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("table", _hoisted_7, [
            _createElementVNode("tbody", null, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(9, (i) => {
                return _createElementVNode("tr", {
                  key: i,
                  class: "border-b last:border-0"
                }, [
                  _createElementVNode("td", _hoisted_8, [
                    _createVNode(_component_ui_skeleton_box, {
                      height: "21.5px",
                      width: "100%"
                    })
                  ])
                ])
              }), 64))
            ])
          ]))
        : (_openBlock(), _createElementBlock("table", _hoisted_9, [
            _createElementVNode("tbody", null, [
              _createElementVNode("tr", _hoisted_10, [
                _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.$t('Store')), 1),
                _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.invoiceDetails.storeName), 1)
              ]),
              _createElementVNode("tr", _hoisted_13, [
                _createElementVNode("th", _hoisted_14, _toDisplayString(_ctx.$t('Invoice Id')), 1),
                _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.invoiceDetails.id), 1)
              ]),
              _createElementVNode("tr", _hoisted_16, [
                _createElementVNode("th", _hoisted_17, _toDisplayString(_ctx.$t('Store Id')), 1),
                _createElementVNode("td", _hoisted_18, _toDisplayString(_ctx.invoiceDetails.storeId), 1)
              ]),
              _createElementVNode("tr", _hoisted_19, [
                _createElementVNode("th", _hoisted_20, _toDisplayString(_ctx.$t('Status')), 1),
                _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.invoiceDetails.status), 1)
              ]),
              _createElementVNode("tr", _hoisted_22, [
                _createElementVNode("th", _hoisted_23, _toDisplayString(_ctx.$t('Created Date')), 1),
                _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.invoiceDetails.createdAt), 1)
              ]),
              _createElementVNode("tr", _hoisted_25, [
                _createElementVNode("th", _hoisted_26, _toDisplayString(_ctx.$t('Expiration Date')), 1),
                _createElementVNode("td", _hoisted_27, _toDisplayString(_ctx.invoiceDetails.expiredAt), 1)
              ]),
              _createElementVNode("tr", _hoisted_28, [
                _createElementVNode("th", _hoisted_29, _toDisplayString(_ctx.$t('Description')), 1),
                _createElementVNode("td", _hoisted_30, _toDisplayString(_ctx.invoiceDetails.description), 1)
              ]),
              _createElementVNode("tr", _hoisted_31, [
                _createElementVNode("th", _hoisted_32, _toDisplayString(_ctx.$t('Return Url')), 1),
                _createElementVNode("td", _hoisted_33, _toDisplayString(_ctx.invoiceDetails.returnUrl), 1)
              ]),
              _createElementVNode("tr", null, [
                _createElementVNode("th", _hoisted_34, _toDisplayString(_ctx.$t('Price')), 1),
                _createElementVNode("td", _hoisted_35, _toDisplayString(_ctx.invoiceDetails.amount) + " USD", 1)
              ])
            ])
          ]))
    ]),
    _createElementVNode("div", _hoisted_36, [
      _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.$t('Invoice Summary')), 1),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("table", _hoisted_38, [
            _createElementVNode("tbody", null, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                return _createElementVNode("tr", {
                  key: i,
                  class: "border-b last:border-0"
                }, [
                  _createElementVNode("td", _hoisted_39, [
                    _createVNode(_component_ui_skeleton_box, {
                      height: "21.5px",
                      width: "100%"
                    })
                  ])
                ])
              }), 64))
            ])
          ]))
        : (_openBlock(), _createElementBlock("table", _hoisted_40, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_41, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoiceSummaryColumns, (column, i) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: i,
                    scope: "col",
                    class: _normalizeClass(["py-2 pr-3 align-top", column.textAlign])
                  }, [
                    _createElementVNode("div", null, _toDisplayString(column.label), 1)
                  ], 2))
                }), 128))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoiceDetailsAddresses, (item, i) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: i,
                  class: "hover:bg-white"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoiceSummaryColumns, (column, j) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: j,
                      class: _normalizeClass(["py-2 pr-3", column.textAlign])
                    }, [
                      (column.field === 'address')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                            (item.address)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_43, [
                                  _createElementVNode("span", null, _toDisplayString(item.address), 1),
                                  _createVNode(_component_ui_copy_to_clipboard, {
                                    "text-to-copy": item.address
                                  }, null, 8, ["text-to-copy"])
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_44, _toDisplayString(_ctx.$t('Address does not requested yet')), 1))
                          ]))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createTextVNode(_toDisplayString(item[column.field]), 1)
                          ], 64))
                    ], 2))
                  }), 128))
                ]))
              }), 128))
            ])
          ]))
    ]),
    _createElementVNode("div", _hoisted_45, [
      _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.$t('Transactions')), 1),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("table", _hoisted_47, [
            _createElementVNode("tbody", null, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(2, (i) => {
                return _createElementVNode("tr", {
                  key: i,
                  class: "border-b last:border-0"
                }, [
                  _createElementVNode("td", _hoisted_48, [
                    _createVNode(_component_ui_skeleton_box, {
                      height: "21.5px",
                      width: "100%"
                    })
                  ])
                ])
              }), 64))
            ])
          ]))
        : (_openBlock(), _createElementBlock("table", _hoisted_49, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_50, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoicetransactionsColumns, (column, i) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: i,
                    scope: "col",
                    class: _normalizeClass(["py-2 pr-3 align-top", column.textAlign])
                  }, [
                    _createElementVNode("div", null, _toDisplayString(column.label), 1)
                  ], 2))
                }), 128))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoiceDetailsTransactions, (item, i) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: i,
                  class: "hover:bg-white"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoicetransactionsColumns, (column, j) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: j,
                      class: _normalizeClass(["py-2 pr-3", column.textAlign])
                    }, [
                      (column.field === 'tx')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                            _createElementVNode("span", null, _toDisplayString(`${item.tx.slice(0, 7)}...${item.tx.slice(-5)}`), 1),
                            _createVNode(_component_ui_copy_to_clipboard, {
                              "text-to-copy": item.tx
                            }, null, 8, ["text-to-copy"])
                          ]))
                        : (column.field === 'sender' && item.sender)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                              _createElementVNode("span", null, _toDisplayString(item.sender), 1),
                              _createVNode(_component_ui_copy_to_clipboard, {
                                "text-to-copy": item.sender
                              }, null, 8, ["text-to-copy"])
                            ]))
                          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                              _createTextVNode(_toDisplayString(item[column.field]), 1)
                            ], 64))
                    ], 2))
                  }), 128))
                ]))
              }), 128))
            ])
          ]))
    ])
  ]))
}