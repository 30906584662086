import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d1252634"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "webhook-settings" }
const _hoisted_2 = { class: "text-2xl font-600 mb-6" }
const _hoisted_3 = { class: "form-inputs" }
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = {
  "data-required": "",
  for: "payloadUrl",
  class: "self-start"
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  for: "secretBase",
  class: "self-start"
}
const _hoisted_8 = { class: "flex items-center gap-4" }
const _hoisted_9 = { class: "text-2xl font-600 mb-6" }
const _hoisted_10 = { class: "flex flex-col gap-4" }
const _hoisted_11 = {
  key: 0,
  class: "flex flex-col gap-4"
}
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_switcher = _resolveComponent("ui-switcher")!
  const _component_ui_checkbox = _resolveComponent("ui-checkbox")!
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!
  const _directive_regexp_validate = _resolveDirective("regexp-validate")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Webhook Settings')), 1),
    _createElementVNode("form", {
      class: "form",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateWebhookHandler && _ctx.updateWebhookHandler(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Payload URL')), 1),
          _withDirectives(_createElementVNode("input", {
            required: "",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.payloadUrl) = $event)),
            id: "payloadUrl",
            class: "input",
            type: "url",
            pattern: "https?://.+"
          }, null, 512), [
            [_vModelText, _ctx.payloadUrl],
            [_directive_regexp_validate, _ctx.$t('Please match the url format')]
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('Secret')), 1),
          _withDirectives(_createElementVNode("input", {
            disabled: "",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.secretBase) = $event)),
            id: "secretBase",
            class: "input",
            type: "text"
          }, null, 512), [
            [_vModelText, _ctx.secretBase]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_ui_switcher, {
          checked: _ctx.enabled,
          onSwitched: _ctx.switchEnabledHandler
        }, null, 8, ["checked", "onSwitched"]),
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('Enabled')), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('Events')), 1),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", null, _toDisplayString(_ctx.$t('Which events would you like to trigger this webhook?')), 1),
          (_ctx.webhookTypes.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.eventsSelected, (item, i) => {
                  return (_openBlock(), _createBlock(_component_ui_checkbox, {
                    class: "self-start",
                    key: i,
                    id: item.value,
                    label: item.title,
                    modelValue: item.checked,
                    "onUpdate:modelValue": ($event: any) => ((item.checked) = $event)
                  }, null, 8, ["id", "label", "modelValue", "onUpdate:modelValue"]))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.webhookId)
        ? (_openBlock(), _createBlock(_component_ui_button_loading, {
            key: 0,
            loading: _ctx.buttonLoading,
            class: "button button-primary px-4 py-2 self-start"
          }, {
            default: _withCtx(() => [
              (_ctx.webhookId === 'new')
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(_ctx.$t('Add webhook')), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(_ctx.$t('Update webhook')), 1))
            ]),
            _: 1
          }, 8, ["loading"]))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}