import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9502136"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "bg-white p-6 relative rounded-sm shadow-sm border flex gap-10 <tablet:(gap-4 flex-col)"
}
const _hoisted_2 = { class: "data-title" }
const _hoisted_3 = { class: "data-amount" }
const _hoisted_4 = { class: "data-title" }
const _hoisted_5 = { class: "data-amount" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_skeleton_box = _resolveComponent("ui-skeleton-box")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_ui_skeleton_box, {
        key: 0,
        width: "100%",
        height: _ctx.skeletonHeight
      }, null, 8, ["height"]))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Number of invoices')), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.history.invoicesCount), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('Number of transactions')), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.history.transactionsCount), 1)
        ])
      ]))
}