import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-130f6621"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-3xl font-600 mb-6" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = {
  for: "address",
  class: "self-start"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = {
  for: "withdrawalsSwitcher",
  class: "self-start"
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  for: "minBalance",
  class: "self-start"
}
const _hoisted_8 = { class: "form-group" }
const _hoisted_9 = {
  for: "intervalSelect",
  class: "self-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_switcher = _resolveComponent("ui-switcher")!
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_ui_expand = _resolveComponent("ui-expand")!
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('Withdrawals Settings')), 1),
    _createElementVNode("form", {
      class: "form",
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateWithdrawalsHandler && _ctx.updateWithdrawalsHandler(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Address')), 1),
        _withDirectives(_createElementVNode("input", {
          required: "",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.address) = $event)),
          id: "address",
          class: "input",
          type: "text"
        }, null, 512), [
          [_vModelText, _ctx.address]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Enable automatic withdrawals')), 1),
        _createVNode(_component_ui_switcher, {
          id: "withdrawalsSwitcher",
          checked: _ctx.enabledWithdrawals,
          onSwitched: _ctx.switchHandler
        }, null, 8, ["checked", "onSwitched"])
      ]),
      _createVNode(_component_ui_expand, {
        class: "flex flex-col gap-10",
        "is-expanded": _ctx.enabledWithdrawals
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('Balance (USD) more than')), 1),
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.minBalance) = $event)),
              class: "input max-w-200px",
              id: "minBalance",
              type: "number"
            }, null, 512), [
              [_vModelText, _ctx.minBalance]
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('Interval')), 1),
            (_ctx.withdrawalIntervals.length)
              ? (_openBlock(), _createBlock(_component_ui_select, {
                  key: 0,
                  id: "intervalSelect",
                  class: "max-w-200px h-42px",
                  modelValue: _ctx.intervalSelected,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.intervalSelected) = $event)),
                  options: _ctx.withdrawalIntervals
                }, null, 8, ["modelValue", "options"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["is-expanded"]),
      _createVNode(_component_ui_button_loading, {
        class: "button button-primary py-2 px-4 self-start",
        type: "submit",
        loading: _ctx.buttonLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Save')), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ], 32)
  ]))
}