import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a2acd34"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "skeleton-wrapper"
}
const _hoisted_2 = {
  key: 1,
  class: "bg-white p-6 balances-chart-wrapper relative rounded-sm shadow-sm border"
}
const _hoisted_3 = { class: "flex flex-col gap-2 font-600 text-sm px-2" }
const _hoisted_4 = { class: "flex justify-between items-center" }
const _hoisted_5 = { class: "opacity-55 text-base" }
const _hoisted_6 = { class: "flex justify-between items-center" }
const _hoisted_7 = { class: "text-2xl" }
const _hoisted_8 = { class: "flex gap-2" }
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_skeleton_box = _resolveComponent("ui-skeleton-box")!
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_vue_apex_charts = _resolveComponent("vue-apex-charts")!
  const _component_ui_loading = _resolveComponent("ui-loading")!

  return (_ctx.skeletonLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_ui_skeleton_box, {
          width: "100%",
          height: "335px"
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('Incoming Payments')), 1),
            _createVNode(_component_ui_select, {
              class: "h-30px",
              id: "ratesSelect",
              modelValue: _ctx.currencySelected,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currencySelected) = $event)),
              options: _ctx.cryptoCurrencies,
              onChange: _ctx.selectCurrencyHandler
            }, null, 8, ["modelValue", "options", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.balance), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeRangeOptions, (range, i) => {
                return (_openBlock(), _createElementBlock("span", {
                  "aria-hidden": "true",
                  key: i,
                  class: _normalizeClass(["cursor-pointer opacity-55", { 'text-sky-500 opacity-100': _ctx.timeRange === range.value }]),
                  onClick: ($event: any) => (_ctx.selectTimeRangeHandler(range.value))
                }, _toDisplayString(range.title), 11, _hoisted_9))
              }), 128))
            ])
          ])
        ]),
        _createVNode(_component_vue_apex_charts, {
          type: "line",
          height: "200",
          width: "100%",
          options: _ctx.options,
          series: _ctx.series
        }, null, 8, ["options", "series"]),
        _createVNode(_component_ui_loading, { loading: _ctx.spinLoading }, null, 8, ["loading"])
      ]))
}