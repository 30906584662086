import { MutationTree, Module } from 'vuex';
import { RootState } from '@/store/types';

interface AppState {
  locale: string;
}

const state: AppState = {
  locale: localStorage.getItem('locale') || 'en-US',
};

const mutations: MutationTree<AppState> = {
  changeLocale(state, value: string) {
    state.locale = value;
    localStorage.setItem('locale', value);
  },
};

export const app: Module<AppState, RootState> = {
  namespaced: true,
  state,
  mutations,
};
