
import { defineComponent } from 'vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import VueApexCharts from 'vue3-apexcharts';
import UiSkeletonBox from '@/components/ui/UiSkeletonBox.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import { round } from '@/utils';

export default defineComponent({
  props: {
    storeSelected: {
      type: String,
    },
  },

  components: {
    VueApexCharts,
    UiSkeletonBox,
    UiLoading,
    UiSelect,
  },

  data() {
    return {
      currency: '',
      timeRange: 'week',
      timeRangeOptions: [
        {
          title: '1W',
          value: 'week',
        },
        {
          title: '1M',
          value: 'month',
        },
        {
          title: '1Y',
          value: 'year',
        },
      ],
      currencySelected: 'BTC.Bitcoin',
      skeletonLoading: false,
      spinLoading: false,
    };
  },

  computed: {
    series(): { name: string, data: [] }[] {
      return [
        {
          name: 'Balance',
          data: this.balances,
        },
      ];
    },

    options() {
      return {
        colors: ['#10b981'],
        chart: {
          id: 'balance-chart',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        tooltip: {
          x: {
            show: true,
            format: 'dd MMM yyyy',
          },
        },
        xaxis: {
          type: 'datetime',
          datetimeUTC: false,
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          tickAmount: 4,
          labels: {
            formatter: (value: number) => {
              return round(value, this.precision);
            },
          },
        },
        markers: {
          size: 5,
        },
      };
    },

    balance() {
      return this.walletBalance(this.currencySelected) || 0;
    },

    precision() {
      return this.cryptoCurrencyPrecision(this.currencySelected);
    },

    ...mapState('dashboard', ['balances']),
    ...mapState('wallets', ['isWalletsLoading']),
    ...mapGetters('wallets', ['walletBalance']),
    ...mapGetters('dictionaries', {
      cryptoCurrencies: 'cryptoCurrencies',
      cryptoCurrencyPrecision: 'cryptoCurrencyPrecision',
    }),
  },

  watch: {
    storeSelected() {
      this.skeletonLoading = true;
      this.loadBalancesData();
    },

    cryptoCurrencies() {
      this.syncDataFromVuex();
    },
  },

  created() {
    if (!this.balances.length) {
      this.skeletonLoading = true;
    }
    this.loadBalancesData();
    if (!this.isWalletsLoading) {
      this.loadWallets();
    }
  },

  mounted() {
    this.syncDataFromVuex();
  },

  unmounted() {
    this.setIsWalletsLoading(false);
  },

  methods: {
    selectCurrencyHandler() {
      this.spinLoading = true;
      this.loadBalancesData();
    },

    selectTimeRangeHandler(value: string) {
      this.timeRange = value;
      this.spinLoading = true;
      this.loadBalancesData();
    },

    async loadBalancesData() {
      try {
        await this.loadBalances({
          range: this.timeRange,
          currencyId: this.currencySelected,
        });
        this.skeletonLoading = false;
        this.spinLoading = false;
      } catch (e) {
        this.skeletonLoading = false;
        this.spinLoading = false;
      }
    },

    syncDataFromVuex() {
      this.currencySelected = this.cryptoCurrencies[0]?.value;
    },

    ...mapActions('dashboard', ['loadBalances']),
    ...mapActions('wallets', ['loadWallets']),
    ...mapMutations('wallets', ['setIsWalletsLoading']),
  },
});
