import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c6b54e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox-wrapper" }
const _hoisted_2 = ["id", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: _ctx.id,
      checked: _ctx.modelValue,
      class: "checkbox",
      type: "checkbox",
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkboxHandler && _ctx.checkboxHandler(...args)))
    }, null, 40, _hoisted_2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "ml-4"
        }, _toDisplayString(_ctx.label), 9, _hoisted_3))
      : _createCommentVNode("", true),
    (_ctx.modelValue)
      ? (_openBlock(), _createBlock(_component_fa_icon, {
          key: 1,
          icon: "check",
          class: "check-icon"
        }))
      : _createCommentVNode("", true)
  ]))
}