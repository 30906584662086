import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a135463"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-3xl text-center font-600 mb-6" }
const _hoisted_2 = {
  key: 0,
  class: "text-center text-sm mb-4"
}
const _hoisted_3 = { class: "invoice" }
const _hoisted_4 = {
  key: 1,
  class: "tabs"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex flex-col" }
const _hoisted_7 = { class: "text-sm opacity-55 font-600" }
const _hoisted_8 = { class: "relative" }
const _hoisted_9 = {
  key: 2,
  class: "qr-code-blur"
}
const _hoisted_10 = { class: "bg-sky-600 rounded-20px relative flex items-center z-100" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "px-2" }
const _hoisted_13 = { class: "flex flex-col gap-8" }
const _hoisted_14 = { class: "flex flex-col gap-2" }
const _hoisted_15 = {
  key: 1,
  class: "uppercase opacity-55 text-sm font-600"
}
const _hoisted_16 = { key: 3 }
const _hoisted_17 = {
  key: 0,
  class: "font-600 flex items-center justify-center gap-2"
}
const _hoisted_18 = { class: "break-all" }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "flex flex-col gap-2" }
const _hoisted_21 = {
  key: 1,
  class: "uppercase opacity-55 text-sm font-600"
}
const _hoisted_22 = { key: 0 }
const _hoisted_23 = {
  key: 3,
  class: "flex flex-col gap-1"
}
const _hoisted_24 = {
  key: 0,
  class: "font-600 text-2xl"
}
const _hoisted_25 = {
  key: 1,
  class: "font-600"
}
const _hoisted_26 = { class: "text-sm font-600 opacity-55" }
const _hoisted_27 = { class: "flex items-center gap-2" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 1 }
const _hoisted_30 = {
  key: 0,
  class: "flex"
}
const _hoisted_31 = { key: 0 }
const _hoisted_32 = {
  key: 2,
  class: "invoice-msg"
}
const _hoisted_33 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_skeleton_box = _resolveComponent("ui-skeleton-box")!
  const _component_ui_qrcode = _resolveComponent("ui-qrcode")!
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!
  const _component_ui_copy_to_clipboard = _resolveComponent("ui-copy-to-clipboard")!
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('Pay with')), 1),
    (_ctx.invoice.description)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.invoice.description), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_ui_skeleton_box, {
            key: 0,
            height: "59px",
            width: "100%",
            class: "shadow-md"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoiceAddresses, (address, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: i,
                "aria-hidden": "true",
                class: _normalizeClass(["tab-item", {'tab-item-active': _ctx.tabActive === i}]),
                onClick: ($event: any) => (_ctx.tabHandler(i))
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("span", null, _toDisplayString(address.currency.split('.')[0]), 1),
                  _createElementVNode("span", _hoisted_7, " (" + _toDisplayString(address.currency.split('.')[1]) + ") ", 1)
                ])
              ], 10, _hoisted_5))
            }), 128))
          ])),
      _createElementVNode("div", {
        class: _normalizeClass(["invoice-content", [_ctx.invoiceTimerData.total ? 'pb-16' : 'pb-10']])
      }, [
        _createElementVNode("div", _hoisted_8, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_ui_skeleton_box, {
                key: 0,
                width: "256px",
                height: "256px"
              }))
            : (_openBlock(), _createBlock(_component_ui_qrcode, {
                key: 1,
                value: _ctx.invoiceAddresses[_ctx.tabActive].address,
                size: 256,
                img: 
              _ctx.invoiceAddresses[_ctx.tabActive].address
                ? `${_ctx.invoiceAddresses[_ctx.tabActive].currency}.svg`
                : ''
            
              }, null, 8, ["value", "img"])),
          (!_ctx.loading && !_ctx.invoiceAddresses[_ctx.tabActive].address)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  (_ctx.invoiceAddresses[_ctx.tabActive].blockchain)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "w-40px absolute left-0 z-101",
                        src: require(`@/assets/img/${_ctx.invoiceAddresses[_ctx.tabActive].currency}.svg`),
                        alt: "crypto-icon"
                      }, null, 8, _hoisted_11))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ui_button_loading, {
                    class: "button button-primary h-40px pl-12 pr-3 !rounded-20px",
                    loading: _ctx.showAddressBtnLoading,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadInvoiceAddressHandler(_ctx.invoiceAddresses[_ctx.tabActive].currency)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Show Address')), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_ui_skeleton_box, {
                    key: 0,
                    height: "20px",
                    width: "100%"
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.$t('Address')) + " (" + _toDisplayString(_ctx.invoiceAddresses[_ctx.tabActive].blockchain) + ") ", 1)),
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_ui_skeleton_box, {
                    key: 2,
                    height: "24px",
                    width: "100%"
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                    (_ctx.invoiceAddresses[_ctx.tabActive].address)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.invoiceAddresses[_ctx.tabActive].address), 1),
                          _createVNode(_component_ui_copy_to_clipboard, {
                            "text-to-copy": _ctx.invoiceAddresses[_ctx.tabActive].address
                          }, null, 8, ["text-to-copy"])
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(30, (i) => {
                            return _createElementVNode("span", { key: i }, "●")
                          }), 64))
                        ]))
                  ]))
            ]),
            _createElementVNode("div", _hoisted_20, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_ui_skeleton_box, {
                    key: 0,
                    height: "20px",
                    width: "100%"
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    (!!_ctx.invoice.amount)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.$t('Amount')), 1))
                      : _createCommentVNode("", true)
                  ])),
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_ui_skeleton_box, {
                    key: 2,
                    height: "84px",
                    width: "100%"
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    (!!_ctx.invoice.amount)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_24, _toDisplayString(_ctx.invoiceAddresses[_ctx.tabActive].amount) + " " + _toDisplayString(_ctx.invoiceAddresses[_ctx.tabActive].currency.split('.')[0]), 1))
                      : _createCommentVNode("", true),
                    (!!_ctx.invoice.amount)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_25, " $" + _toDisplayString(_ctx.invoice.amount) + " (USD) ", 1))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_26, " 1 " + _toDisplayString(_ctx.invoiceAddresses[_ctx.tabActive].currency.split('.')[0]) + " = " + _toDisplayString(_ctx.invoiceAddresses[_ctx.tabActive].rate) + " (USD) ", 1)
                  ]))
            ])
          ])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["awaiting-payment", {'!bg-gray-400': _ctx.invoiceMsgStatus.type === 'confirm'}])
        }, [
          _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_fa_icon, {
              icon: "spinner",
              class: "animate-spin"
            }),
            (_ctx.invoiceMsgStatus.type === 'confirm')
              ? (_openBlock(), _createElementBlock("span", _hoisted_28, _toDisplayString(_ctx.invoiceMsgStatus.text), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(_ctx.$t('Awaiting Payment')) + "... ", 1))
          ]),
          (_ctx.invoiceTimerData.total && _ctx.invoiceMsgStatus.type !== 'confirm')
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                (_ctx.invoiceTimerData.days !== '0')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_ctx.invoiceTimerData.days) + ": ", 1))
                  : _createCommentVNode("", true),
                _createElementVNode("span", null, _toDisplayString(_ctx.invoiceTimerData.hours) + ": ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.invoiceTimerData.minutes) + ": ", 1),
                _createElementVNode("span", null, _toDisplayString(_ctx.invoiceTimerData.seconds), 1)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ], 2),
      (_ctx.invoiceMsgStatus.formHide)
        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
            _createElementVNode("div", null, _toDisplayString(_ctx.invoiceMsgStatus.text), 1),
            (_ctx.invoice.returnUrl)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "button button-primary py-2 px-4",
                  href: _ctx.invoice.returnUrl
                }, _toDisplayString(_ctx.$t('Back to store')), 9, _hoisted_33))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}