import { api } from '@/api/Api';
import { AxiosBaseResponse, Token } from '@/api/types';
import {
  BalancesResponse,
  HistoryResponse,
  RecentTxsResponse,
  RecentInvoicesResponse,
  ProcessingWalletsResponse,
} from './types';

const urls = {
  balances: '/dashboard/balances',
  history: '/dashboard/history',
  transactions: '/dashboard/transactions',
  invoices: '/invoices',
  wallets: '/processing/wallets',
};

export default class DashboardService {
  public static async getBalances(
    storeId: string,
    params: Record<string | number, string | number>,
    token: Token,
  ): Promise<AxiosBaseResponse<BalancesResponse[]>> {
    return api.get(`/stores/${storeId}${urls.balances}`, params, token);
  }

  public static async getHistory(storeId: string, token: Token): Promise<AxiosBaseResponse<HistoryResponse>> {
    return api.get(`/stores/${storeId}${urls.history}`, null, token);
  }

  public static async getRecentTransactions(storeId: string, token: Token): Promise<AxiosBaseResponse<RecentTxsResponse[]>> {
    return api.get(`/stores/${storeId}${urls.transactions}`, null, token);
  }

  public static async getRecentInvoices(
    storeId: string,
    params: Record<string | number, string | number>,
    token: Token,
  ): Promise<AxiosBaseResponse<RecentInvoicesResponse[]>> {
    return api.get(`/stores/${storeId}${urls.invoices}`, params, token);
  }

  public static async getProcessingWallets(storeId: string, token: Token): Promise<AxiosBaseResponse<ProcessingWalletsResponse[]>> {
    return api.get(`stores/${storeId}${urls.wallets}`, null, token);
  }

  public static dateSort<T extends BalancesResponse>(a: T, b: T): number {
    return new Date(b.date).valueOf() - new Date(a.date).valueOf();
  }
}
