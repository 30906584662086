
import { defineComponent } from 'vue';

export default defineComponent({
  computed: {
    webhooksTabActive() {
      const input = 'webhooks';
      return this.$route.path.split('/').find((item) => item === input);
    },
  },
});
