import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { useToast } from 'vue-toastification';
import { RootState } from '@/store/types';
import { PaginationResponse } from '@/api/types';
import WalletsService from '@/services/WalletsService';
import {
  CreateWalletRequest,
  WalletsResponse,
  WalletBalance,
  WithdrawalsSettingsResponse,
  CompletedWithdrawalsResponse,
} from '@/services/WalletsService/types';
import { decimal, round, dateFormat } from '@/utils';

const toast = useToast();

interface WalletsState {
  wallets: WalletsResponse[];
  readonly: boolean;
  mnemonic: string;
  passPhrase: string;
  confirmPassPhrase: string;
  shouldWalletsBeLoaded: boolean;
  isWalletsLoading: boolean;
  withdrawalsSettings: Partial<WithdrawalsSettingsResponse>;
  completedWithdrawals: CompletedWithdrawalsResponse[];
  completedWithdrawalsPagination: Partial<PaginationResponse>;
}

const state: WalletsState = {
  wallets: [],
  readonly: false,
  mnemonic: '',
  passPhrase: '',
  confirmPassPhrase: '',
  isWalletsLoading: false,
  shouldWalletsBeLoaded: false,
  withdrawalsSettings: {},
  completedWithdrawals: [],
  completedWithdrawalsPagination: {},
};

const getters: GetterTree<WalletsState, RootState> = {
  isReadonly(state) {
    if (state.readonly) {
      return state.readonly;
    }
    return localStorage.getItem('readonly') === 'true';
  },

  walletBalance(state) {
    return (currencyId: string) => {
      return state.wallets.reduce((acc, curr) => {
        const find = curr.balance.find((elem) => elem.currencyId === currencyId)?.balance;
        if (find) {
          return `${find} ${currencyId.split('.')[0]}`;
        }
        return acc;
      }, '');
    };
  },

  currencies(state) {
    return state.wallets
      .map((item) => {
        return {
          balance: item.balance.map((elem) => {
            return {
              ...elem,
              walletId: item.id,
            };
          }),
        };
      })
      .reduce((acc, curr) => {
        return acc.concat(...curr.balance);
      }, [] as (WalletBalance & { walletId: string })[]);
  },
};

const mutations: MutationTree<WalletsState> = {
  setWallets(state, value: WalletsResponse[]) {
    state.wallets = [...value].map((item) => {
      return {
        ...item,
        balance: item.balance.map((elem) => {
          return {
            ...elem,
            balance: decimal(elem.balance),
            balanceUsd: round(elem.balanceUsd, 2),
          };
        }),
      };
    });
  },

  setReadOnly(state, value: boolean) {
    state.readonly = value;
    localStorage.setItem('readonly', value.toString());
  },

  setMnemonic(state, value: string) {
    state.mnemonic = value;
  },

  setPassPhrase(state, value: string) {
    state.passPhrase = value;
  },

  setConfirmPassPhrase(state, value: string) {
    state.confirmPassPhrase = value;
  },

  setShouldWalletsBeLoaded(state, value: boolean) {
    state.shouldWalletsBeLoaded = value;
  },

  setIsWalletsLoading(state, value: boolean) {
    state.isWalletsLoading = value;
  },

  setWithdrawalsSettings(state, value: WithdrawalsSettingsResponse) {
    state.withdrawalsSettings = {
      ...value,
      minBalance: decimal(value.minBalance),
    };
  },

  clearWithdrawalsSettings(state) {
    state.withdrawalsSettings = {};
  },

  setCompletedWithdrawals(state, value: CompletedWithdrawalsResponse[]) {
    state.completedWithdrawals = [...value]
      .map((item) => {
        return {
          ...item,
          createdAt: dateFormat(item.createdAt),
          amount: decimal(item.amount),
        };
      });
  },

  setCompletedWithdrawalsPagination(state, value: PaginationResponse) {
    state.completedWithdrawalsPagination = {
      total: value.total,
      per_page: value.per_page,
      current_page: value.current_page,
    };
  },
};

const actions: ActionTree<WalletsState, RootState> = {
  async loadWallets(context) {
    try {
      if (!Object.keys(context.rootState.dictionaries.dictionaries).length) {
        context.commit('dictionaries/setIsDictionariesLoading', true, { root: true });
        await context.dispatch('dictionaries/loadDictionaries', null, { root: true });
      }

      const { data } = await WalletsService.getWallets(
        context.rootGetters['stores/getStoreSelected'],
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;

      let filledResult;

      if (result.length) {
        filledResult = await context.dispatch('filledWallets', result);
      } else {
        filledResult = result;
      }
      context.commit('setWallets', filledResult);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  filledWallets(context, wallets) {
    const blockchains = context.rootGetters['dictionaries/blockchainsArray'];

    return blockchains.map((item: string) => {
      const find = wallets.find((wallet: WalletsResponse) => wallet.blockchain === item);
      if (find) {
        return find;
      }
      return {
        id: '',
        address: '',
        blockchain: item,
        storeId: '',
        balance: [],
      };
    }).sort(WalletsService.compareWallets);
  },

  async createWallets(context, payload: CreateWalletRequest) {
    try {
      await WalletsService.createWallets(
        context.rootGetters['stores/getStoreSelected'],
        payload,
        context.rootGetters['auth/accessToken'],
      );
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async importAddress(context, { blockchain, payload }) {
    try {
      await WalletsService.importAddress(
        context.rootGetters['stores/getStoreSelected'],
        blockchain,
        payload,
        context.rootGetters['auth/accessToken'],
      );
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async importMnemonic(context, { blockchain, payload }) {
    try {
      await WalletsService.importMnemonic(
        context.rootGetters['stores/getStoreSelected'],
        blockchain,
        payload,
        context.rootGetters['auth/accessToken'],
      );
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async generateMnemonic(context) {
    try {
      const { data } = await WalletsService.generateMnemonic();
      const { result } = data;
      context.commit('setMnemonic', result[0]);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async loadWithdrawalsSettings(context, walletId) {
    try {
      const { data } = await WalletsService.getWithdrawalsSettings(
        context.rootGetters['stores/getStoreSelected'],
        walletId,
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('setWithdrawalsSettings', result);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async updateWithdrawalsSettings(context, { walletId, payload }) {
    try {
      await WalletsService.updateWithdrawalsSettings(
        context.rootGetters['stores/getStoreSelected'],
        walletId,
        payload,
        context.rootGetters['auth/accessToken'],
      );
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async withdrawalsRequest(context, payload) {
    try {
      await WalletsService.withdrawalsRequest(
        context.rootGetters['stores/getStoreSelected'],
        payload,
        context.rootGetters['auth/accessToken'],
      );
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async loadCompletedWithdrawals(context, params) {
    try {
      const { data } = await WalletsService.getCompletedWithdrawals(
        context.rootGetters['stores/getStoreSelected'],
        params,
        context.rootGetters['auth/accessToken'],
      );
      const { result, meta } = data;
      context.commit('setCompletedWithdrawalsPagination', meta);
      context.commit('setCompletedWithdrawals', result);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },
};

export const wallets: Module<WalletsState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
