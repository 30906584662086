import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cf964e10"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "spinner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "relative",
    disabled: _ctx.loading
  }, [
    _createElementVNode("span", {
      class: _normalizeClass({'opacity-0': _ctx.loading})
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_fa_icon, {
            icon: "spinner",
            size: "lg",
            class: "animate-spin text-white"
          })
        ]))
      : _createCommentVNode("", true)
  ], 8, _hoisted_1))
}