
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
    },
  },

  emits: ['update:modelValue'],

  methods: {
    closeHandler() {
      this.$emit('update:modelValue', false);
    },
  },
});
