import {
  GetterTree, MutationTree, ActionTree, Module,
} from 'vuex';
import { useToast } from 'vue-toastification';
import AuthService from '@/services/AuthService';
import {
  LoginUserRequest,
  OtpRequest,
  RegisterUserRequest,
  ResetPasswordConfirmRequest,
  ResetPasswordRequest,
  ValidateTokenRequest,
} from '@/services/AuthService/types';
import { RootState } from '@/store/types';
import { Token } from '@/api/types';
import router from '@/router';

const toast = useToast();

interface AuthState {
  accessToken: Token;
  otpToken: Token;
}

const state: AuthState = {
  accessToken: null,
  otpToken: null,
};

const getters: GetterTree<AuthState, RootState> = {
  isAuth(state, getters): boolean {
    return !!getters.accessToken;
  },

  accessToken(state): Token {
    return state.accessToken || localStorage.getItem('access_token');
  },

  isOtpPassed(state, getters): boolean {
    return !getters.otpToken;
  },

  otpToken(state): Token {
    return state.otpToken;
  },
};

const mutations: MutationTree<AuthState> = {
  setAccessToken(state, value: Token) {
    state.accessToken = value;
    if (value) {
      localStorage.setItem('access_token', value);
    }
  },

  removeAccessToken(state) {
    state.accessToken = null;
    localStorage.removeItem('access_token');
  },

  setOtpToken(state, value: Token) {
    state.otpToken = value;
  },
};

const actions: ActionTree<AuthState, RootState> = {
  async loginUser(context, payload: LoginUserRequest) {
    try {
      const { data } = await AuthService.loginUser(payload);
      const { result } = data;
      if (result.need_otp) {
        context.commit('setAccessToken', null);
        context.commit('setOtpToken', result.token);
      } else {
        context.commit('setAccessToken', result.token);
        context.commit('setOtpToken', null);

        if (!Object.keys(context.rootState.dictionaries.dictionaries).length) {
          await context.dispatch('dictionaries/loadDictionaries', null, { root: true });
        }
      }
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async loginUserWithOTP(context, payload: OtpRequest) {
    try {
      const { data } = await AuthService.checkOTP({
        ...payload,
        token: context.getters.otpToken,
      });
      const { result } = data;

      context.commit('setAccessToken', result.token);
      context.commit('setOtpToken', null);

      if (!Object.keys(context.rootState.dictionaries.dictionaries).length) {
        await context.dispatch('dictionaries/loadDictionaries', null, { root: true });
      }
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async registerUser(_, payload: RegisterUserRequest) {
    try {
      await AuthService.registerUser(payload);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async resetPassword(_, payload: ResetPasswordRequest) {
    try {
      await AuthService.resetPassword(payload);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async resetPasswordConfirm(_, payload: ResetPasswordConfirmRequest) {
    try {
      await AuthService.resetPasswordConfirm(payload);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async validateToken(_, payload: ValidateTokenRequest) {
    try {
      await AuthService.validateToken(payload);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async logoutUser(context) {
    try {
      try {
        await AuthService.logoutUser(context.getters.accessToken);
      } catch (e) {
      }

      context.commit('removeAccessToken');
      context.commit('stores/removeStores', null, { root: true });
      context.commit('resetStore', null, { root: true });
      await router.push({ name: 'login' });
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async validateAccessToken(context, payload: ValidateTokenRequest) {
    try {
      await AuthService.validateAccessToken(payload.token);
    } catch (e) {
      await context.dispatch('logoutUser');
      toast.error(e.message);
      throw e;
    }
  },
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
