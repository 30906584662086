import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bab589da"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-tooltip"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "wrapper",
    "data-tooltip": _ctx.text
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 8, _hoisted_1))
}