import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bf6b2c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-2xl font-600 mb-6" }
const _hoisted_2 = { class: "flex flex-col gap-2" }
const _hoisted_3 = {
  "data-required": "",
  for: "orderId",
  class: "self-start"
}
const _hoisted_4 = { class: "flex flex-col gap-2" }
const _hoisted_5 = {
  for: "webhookTypes",
  class: "self-start"
}
const _hoisted_6 = {
  key: 0,
  class: "webhook-response"
}
const _hoisted_7 = { class: "flex items-center gap-2 px-2 py-1" }
const _hoisted_8 = { class: "font-600" }
const _hoisted_9 = { class: "text-cyan-500" }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('Send a test event to a webhook endpoint')), 1),
    _createElementVNode("form", {
      class: "form",
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.sendTestWebhookHandler && _ctx.sendTestWebhookHandler(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Order Id')), 1),
        _withDirectives(_createElementVNode("input", {
          required: "",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.orderId) = $event)),
          id: "orderId",
          class: "input",
          type: "text"
        }, null, 512), [
          [_vModelText, _ctx.orderId]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Event type')), 1),
        _createVNode(_component_ui_select, {
          id: "webhookTypes",
          class: "max-w-280px h-42px",
          modelValue: _ctx.webhookTypeSelected,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.webhookTypeSelected) = $event)),
          options: _ctx.webhookTypes
        }, null, 8, ["modelValue", "options"])
      ]),
      _createVNode(_component_ui_button_loading, {
        class: "button button-primary py-2 px-4 self-start mt-4",
        type: "submit",
        loading: _ctx.buttonLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Send test webhook')), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ], 32),
    (_ctx.testWebhookResponse.status)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", {
            class: _normalizeClass(["text-xl font-600 px-2 py-1 bg-sky-100", [
          _ctx.testWebhookResponse.status >= 200 && _ctx.testWebhookResponse.status < 300
          ? 'bg-sky-100'
          : 'bg-red-100'
        ]])
          }, _toDisplayString(_ctx.$t('webhook response')), 3),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('status')) + ": ", 1),
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.testWebhookResponse.status), 1)
          ]),
          _createElementVNode("pre", {
            innerHTML: _ctx.testWebhookResponse.message,
            class: "pre"
          }, null, 8, _hoisted_10),
          _createElementVNode("button", {
            class: "close",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.removeWebhookResponseBlock && _ctx.removeWebhookResponseBlock(...args)))
          }, [
            _createVNode(_component_fa_icon, {
              icon: "xmark",
              size: "lg"
            })
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}