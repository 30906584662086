import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "bg-white rounded-sm shadow-sm border p-6"
}
const _hoisted_2 = { class: "flex justify-between items-center mb-4" }
const _hoisted_3 = { class: "text-2xl font-600" }
const _hoisted_4 = {
  key: 0,
  class: "table-h-scroll"
}
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { class: "border-b" }
const _hoisted_7 = { class: "text-lg" }
const _hoisted_8 = {
  key: 0,
  class: "break-all"
}
const _hoisted_9 = {
  key: 1,
  class: "opacity-55"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_skeleton_box = _resolveComponent("ui-skeleton-box")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_ui_skeleton_box, {
        key: 0,
        width: "100%",
        height: "122px"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Recent Invoices')), 1),
          (_ctx.recentInvoices.length)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "text-sky-500",
                to: { name: 'invoices', params: { store_id: _ctx.storeSelected } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('View All')), 1)
                ]),
                _: 1
              }, 8, ["to"]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.recentInvoices.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("table", _hoisted_5, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, i) => {
                      return (_openBlock(), _createElementBlock("th", {
                        key: i,
                        scope: "col",
                        class: _normalizeClass(["py-4 pr-3 align-top last:pr-0", column.textAlign])
                      }, [
                        _createElementVNode("div", _hoisted_7, _toDisplayString(column.label), 1)
                      ], 2))
                    }), 128))
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recentInvoices, (item, i) => {
                    return (_openBlock(), _createElementBlock("tr", { key: i }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, j) => {
                        return (_openBlock(), _createElementBlock("td", {
                          key: j,
                          class: _normalizeClass(["py-4 pr-3 last:pr-0", column.textAlign])
                        }, [
                          (column.field === 'id')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(item.id), 1))
                            : (column.field === 'amount')
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(_toDisplayString(item.amount) + " (" + _toDisplayString(item.currency) + ") ", 1)
                                ], 64))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                  _createTextVNode(_toDisplayString(item[column.field]), 1)
                                ], 64))
                        ], 2))
                      }), 128))
                    ]))
                  }), 128))
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('There are no recent invoices.')), 1))
      ]))
}