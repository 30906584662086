import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import { useToast } from 'vue-toastification';
import CryptoES from 'crypto-es';
import StoreService from '@/services/StoreService';
import {
  ApiKeysResponse,
  CreateStoreRequest,
  StoreResponse,
  UpdateRateSourceRequest,
  UpdateStoreRequest,
  WebhookResponse,
  TestWebhookResponse,
} from '@/services/StoreService/types';
import router from '@/router';

const toast = useToast();

export interface StoreSelectOptionType {
  title: string;
  value: string;
}

interface StoresState {
  storeOptions: StoreSelectOptionType[];
  storeSelected: string;
  id: string;
  name: string;
  site: string | null;
  currency: string;
  rateSource: string;
  invoiceExpirationTime: number | null;
  apiKeyList: ApiKeysResponse[];
  webhookList: WebhookResponse[];
  isStoreLoading: boolean;
  testWebhookResponse: Partial<TestWebhookResponse>;
}

const state: StoresState = {
  storeOptions: [],
  storeSelected: '',
  id: '',
  name: '',
  site: '',
  currency: '',
  rateSource: '',
  invoiceExpirationTime: null,
  apiKeyList: [],
  webhookList: [],
  isStoreLoading: false,
  testWebhookResponse: {},
};

const getters: GetterTree<StoresState, RootState> = {
  getStoreSelected(state) {
    if (state.storeSelected) {
      return state.storeSelected;
    }
    return localStorage.getItem('selected_store') || state.storeOptions[0]?.value || '';
  },
};

const mutations: MutationTree<StoresState> = {
  setStoreOptions(state, value: StoreSelectOptionType[]) {
    state.storeOptions = value;
  },

  setStoreSelected(state, value: string) {
    state.storeSelected = value;
    localStorage.setItem('selected_store', value);
  },

  setStore(state, value: StoreResponse) {
    const { id, name, site, currency, rateSource, invoiceExpirationTime } = value;
    state.id = id;
    state.name = name;
    state.site = site;
    state.currency = currency;
    state.rateSource = rateSource;
    state.invoiceExpirationTime = invoiceExpirationTime;
  },

  removeStores(state) {
    state.storeSelected = '';
    state.storeOptions = [];
    localStorage.removeItem('selected_store');
  },

  setApiKeyList(state, value: ApiKeysResponse[]) {
    state.apiKeyList = value;
  },

  setWebhookList(state, value: WebhookResponse[]) {
    state.webhookList = value;
  },

  setIsStoreLoading(state, value: boolean) {
    state.isStoreLoading = value;
  },

  setTestWebhookResponse(state, value: TestWebhookResponse) {
    state.testWebhookResponse = value;
  },
};

const actions: ActionTree<StoresState, RootState> = {
  async loadStores(context) {
    try {
      const { data } = await StoreService.getStores(context.rootGetters['auth/accessToken']);
      const { result } = data;

      const list = result.map((item) => ({
        title: item.name,
        value: item.id,
      }));

      context.commit('setStoreOptions', list);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async loadStoreById(context) {
    try {
      const { data } = await StoreService.getStoreById(
        context.getters.getStoreSelected,
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('setStore', result);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async redirectAfterLoadStores(context, store_id = null) {
    context.commit('wallets/setShouldWalletsBeLoaded', true, { root: true });
    if (context.state.storeOptions.length) {
      await router.push({
        name: 'dashboard',
        params: {
          store_id: store_id ?? context.getters.getStoreSelected,
        },
      });
    } else {
      await router.push({ name: 'store-create' });
    }
  },

  async createStore(context, payload: CreateStoreRequest) {
    try {
      const { data } = await StoreService.createStore(payload, context.rootGetters['auth/accessToken']);
      const { result } = data;
      await context.dispatch('loadStores');
      context.commit('setStoreSelected', result.id);
      await context.dispatch('loadStoreById');
      await context.dispatch('redirectAfterLoadStores', result.id);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async updateStore(context, payload: UpdateStoreRequest) {
    try {
      const { data } = await StoreService.updateStore(
        context.getters.getStoreSelected,
        payload,
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('setStore', result);
      await context.dispatch('loadStores');
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async updateRateSource(context, payload: UpdateRateSourceRequest) {
    try {
      const { data } = await StoreService.updateRateSource(
        context.getters.getStoreSelected,
        payload,
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('setStore', result);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async createApiKey(context) {
    try {
      await StoreService.createApiKey(
        context.getters.getStoreSelected,
        context.rootGetters['auth/accessToken'],
      );
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async loadApiKeys(context) {
    try {
      const { data } = await StoreService.getApiKeys(
        context.getters.getStoreSelected,
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('setApiKeyList', result);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async updateApiKey(context, { apiKeyId, payload }) {
    try {
      await StoreService.updateApiKey(
        context.getters.getStoreSelected,
        apiKeyId,
        payload,
        context.rootGetters['auth/accessToken'],
      );
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async deleteApiKey(context, apiKeyId) {
    try {
      await StoreService.removeApiKey(
        context.getters.getStoreSelected,
        apiKeyId,
        context.rootGetters['auth/accessToken'],
      );
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async generateSecret() {
    const random = CryptoES.lib.WordArray.random(256 / 8);
    return CryptoES.SHA1(random).toString();
  },

  async loadWebhooks(context) {
    try {
      if (!Object.keys(context.rootState.dictionaries.dictionaries).length) {
        context.commit('dictionaries/setIsDictionariesLoading', true, { root: true });
        await context.dispatch('dictionaries/loadDictionaries', null, { root: true });
      }

      const { data } = await StoreService.getWebhooks(
        context.getters.getStoreSelected,
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('setWebhookList', result);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async createWebhook(context, payload) {
    try {
      await StoreService.createWebhook(
        context.getters.getStoreSelected,
        payload,
        context.rootGetters['auth/accessToken'],
      );
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async updateWebhook(context, { webhookId, payload }) {
    try {
      await StoreService.updateWebhook(
        context.getters.getStoreSelected,
        webhookId,
        payload,
        context.rootGetters['auth/accessToken'],
      );
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async deleteWebhook(context, webhookId) {
    try {
      await StoreService.removeWebhook(
        context.getters.getStoreSelected,
        webhookId,
        context.rootGetters['auth/accessToken'],
      );
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async sendTestWebhook(context, { webhookId, payload }) {
    try {
      context.commit('setTestWebhookResponse', {});
      const { data } = await StoreService.sendTestWebhook(
        context.getters.getStoreSelected,
        webhookId,
        payload,
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      if (result.status >= 200 && result.status < 300) {
        toast.success('Webhook test: success');
      } else {
        toast.error('Webhook test: Something went wrong');
      }
      context.commit('setTestWebhookResponse', result);
    } catch (e) {
      toast.error(e.message);
      context.commit('setTestWebhookResponse', {});
      throw e;
    }
  },
};

export const stores: Module<StoresState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
