
import {defineComponent} from 'vue';
import {mapActions} from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import UiLoadingContainer from '@/components/ui/UiLoadingContainer.vue';

export default defineComponent({
  components: {
    UiLoadingContainer,
    UiButtonLoading,
  },

  props: ['token'],

  data() {
    return {
      password: '',
      confirmPassword: '',
      buttonLoading: false,
      isValidToken: false,
      isValidTokenLoading: true,
    };
  },

  async mounted() {
    await this.checkToken();
  },

  methods: {
    async checkToken() {
      try {
        this.isValidTokenLoading = true;
        await this.validateToken({
          token: this.token,
        });
        this.isValidTokenLoading = false;
        this.isValidToken = true;
      } catch (e) {
        this.isValidTokenLoading = false;
        this.isValidToken = false;
      }
    },

    async reset() {
      try {
        this.buttonLoading = true;
        await this.resetPasswordConfirm({
          password: this.password,
          password_confirmation: this.confirmPassword,
          token: this.token,
        });
        this.buttonLoading = false;

        this.$toast.success(this.$t('Password updated successfully. Now you can login with new credentials'));

        this.$router.push({ name: 'login' });
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    ...mapActions('auth', ['validateToken', 'resetPasswordConfirm']),
  },
});
