
import { defineComponent } from 'vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

export default defineComponent({
  components: {
    UiSelect,
    UiButtonLoading,
  },

  data() {
    return {
      storeId: '',
      storeName: '',
      storeWebsite: '',
      storeCurrencySelect: '',
      invoiceExpires: null,
      buttonLoading: false,
    };
  },

  computed: {
    ...mapState('stores', {
      id: 'id',
      name: 'name',
      site: 'site',
      currency: 'currency',
      invoiceExpirationTime: 'invoiceExpirationTime',
    }),
    ...mapGetters('dictionaries', {
      fiatCurrencies: 'fiatCurrencies',
    }),
  },

  watch: {
    id() {
      this.syncDataFromVuex();
    },
  },

  created() {
    this.loadStore();
    this.syncDataFromVuex();
  },

  methods: {
    async updateGeneralSettings() {
      try {
        this.buttonLoading = true;
        await this.updateStore({
          name: this.storeName,
          site: this.storeWebsite,
          currency: this.storeCurrencySelect,
          invoiceExpirationTime: this.invoiceExpires,
        });
        this.buttonLoading = false;
        this.$toast.success(this.$t('Store successfully updated'));
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    async loadStore() {
      this.setIsStoreLoading(true);
      await this.loadStoreById();
    },

    syncDataFromVuex() {
      this.storeId = this.id;
      this.storeName = this.name;
      this.storeWebsite = this.site;
      this.storeCurrencySelect = this.currency;
      this.invoiceExpires = this.invoiceExpirationTime;
    },

    ...mapMutations('stores', ['setIsStoreLoading']),
    ...mapActions('stores', ['updateStore', 'loadStoreById']),
  },
});
