import { api } from '@/api/Api';
import { AxiosBaseResponse, Token } from '@/api/types';
import {
  InvoiceAddresses,
  InvoiceResponse,
  InvoicesResponse,
  InvoiceDetailsResponse,
  InvoiceDetailsTransactions,
  CreateInvoiceRequest,
  CreateInvoiceResponse,
  AddressResponse,
} from './types';

const urls = {
  invoices: '/invoices',
};

export default class InvoicesService {
  public static async getInvoice(invoiceId: string): Promise<AxiosBaseResponse<InvoiceResponse>> {
    return api.get(`${urls.invoices}/${invoiceId}`);
  }

  public static async getAddress(invoiceId: string, currencyId: string): Promise<AxiosBaseResponse<AddressResponse>> {
    return api.get(`${urls.invoices}/${invoiceId}/addresses/${currencyId}`);
  }

  public static async getInvoices(storeId: string, params: Record<string, string>, token: Token): Promise<AxiosBaseResponse<InvoicesResponse>> {
    return api.get(`stores/${storeId}${urls.invoices}`, params, token);
  }

  public static async getInvoiceDetails(storeId: string, invoiceId: string, token: Token): Promise<AxiosBaseResponse<InvoiceDetailsResponse>> {
    return api.get(`/stores/${storeId}${urls.invoices}/${invoiceId}`, null, token);
  }

  public static async createInvoice(storeId: string, payload: CreateInvoiceRequest, token: Token): Promise<AxiosBaseResponse<CreateInvoiceResponse>> {
    return api.post(`/stores/${storeId}${urls.invoices}`, payload, token);
  }

  public static currencySort(a: InvoiceAddresses, b: InvoiceAddresses) {
    return a.currency.localeCompare(b.currency);
  }

  public static dateSort<T extends InvoiceDetailsTransactions>(a: T, b: T): number {
    return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
  }

  public static invoiceTimer(endtime: string) {
    const total = Date.parse(endtime) - Date.parse(new Date().toString());

    if (total > 0) {
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));

      return {
        total,
        days: days.toString(),
        hours: (`0${hours}`).slice(-2),
        minutes: (`0${minutes}`).slice(-2),
        seconds: (`0${seconds}`).slice(-2),
      };
    }

    return {
      total: 0,
      days: '0',
      hours: '00',
      minutes: '00',
      seconds: '00',
    };
  }
}
