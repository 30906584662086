
import { defineComponent } from 'vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

export default defineComponent({
  components: {
    UiSelect,
    UiButtonLoading,
  },

  data() {
    return {
      orderId: '',
      webhookType: '',
      buttonLoading: false,
    };
  },

  computed: {
    webhookTypeSelected: {
      get(): string {
        return this.webhookType
          ? this.webhookType
          : this.webhookTypes[0]?.value;
      },

      set(value) {
        this.webhookType = value;
      },
    },

    webhookId() {
      return this.$route.params.webhook_id;
    },

    ...mapState('dictionaries', ['webhookTypes']),
    ...mapState('stores', ['testWebhookResponse']),
  },

  unmounted() {
    this.removeWebhookResponseBlock();
  },

  methods: {
    async sendTestWebhookHandler() {
      try {
        this.buttonLoading = true;
        await this.sendTestWebhook({
          webhookId: this.webhookId,
          payload: {
            eventType: this.webhookTypeSelected,
            orderId: this.orderId,
          },
        });
        this.buttonLoading = false;
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    removeWebhookResponseBlock() {
      this.setTestWebhookResponse('{}');
    },

    ...mapMutations('stores', ['setTestWebhookResponse']),
    ...mapActions('stores', ['sendTestWebhook']),
  },
});
