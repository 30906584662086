
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

export default defineComponent({
  computed: {
    webhooksTabActive() {
      const input = 'webhooks';
      return this.$route.path.split('/').find((item) => item === input);
    },

    ...mapGetters('stores', {
      getStoreSelected: 'getStoreSelected',
    }),
  },
});
