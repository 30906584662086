import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mb-6" }
const _hoisted_2 = { class: "flex items-center justify-between gap-2" }
const _hoisted_3 = { class: "text-3xl font-600" }
const _hoisted_4 = { class: "flex flex-col gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wallets_withdrawals = _resolveComponent("wallets-withdrawals")!
  const _component_completed_withdrawals = _resolveComponent("completed-withdrawals")!
  const _component_request_withdrawal_form = _resolveComponent("request-withdrawal-form")!
  const _component_ui_modal = _resolveComponent("ui-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Withdrawals')), 1),
        (_ctx.wallets.length)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "button button-primary px-6 py-3 flex items-center gap-2",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showRequestForm && _ctx.showRequestForm(...args)))
            }, _toDisplayString(_ctx.$t('Request a withdrawal')), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_wallets_withdrawals),
      _createVNode(_component_completed_withdrawals)
    ]),
    _createVNode(_component_ui_modal, {
      modelValue: _ctx.showModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModal) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_request_withdrawal_form, { onOnSubmit: _ctx.withdrawalFormSubmit }, null, 8, ["onOnSubmit"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}