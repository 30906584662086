
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import { WebhookResponse } from '@/services/StoreService/types';
import { StoreSelectOptionType } from '@/store/stores';
import UiSwitcher from '@/components/ui/UiSwitcher.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

interface WebhookTypes extends StoreSelectOptionType {
  checked?: boolean;
}

export default defineComponent({
  components: {
    UiSwitcher,
    UiCheckbox,
    UiButtonLoading,
  },

  data() {
    return {
      webhookId: '',
      payloadUrl: '',
      secretBase: '',
      enabled: true,
      eventsSelected: [],
      buttonLoading: false,
    };
  },

  computed: {
    ...mapState('dictionaries', {
      webhookTypes: 'webhookTypes',
    }),
    ...mapState('stores', ['webhookList']),
  },

  watch: {
    webhookList() {
      this.syncDataFromVuex();
    },

    webhookTypes() {
      this.syncDataFromVuex();
    },
  },

  created() {
    this.loadWebhooksData();
    this.syncDataFromVuex();
  },

  methods: {
    switchEnabledHandler() {
      this.enabled = !this.enabled;
    },

    loadWebhooksData() {
      if (this.$route.params.webhook_id !== 'new') {
        this.loadWebhooks();
      }
    },

    async updateWebhookHandler() {
      const selectedEventsArray = this.eventsSelected
        .filter((item: WebhookTypes) => item.checked)
        .map((item: WebhookTypes) => item.value);

      if (this.webhookId === 'new') {
        try {
          this.buttonLoading = true;
          await this.createWebhook({
            url: this.payloadUrl,
            secret: this.secretBase,
            enabled: this.enabled,
            events: selectedEventsArray,
          });
          this.buttonLoading = false;
          this.$router.push({ name: 'store-settings-webhooks' });
          this.$toast.success(this.$t('Webhook successfully created'));
        } catch (e) {
          this.buttonLoading = false;
        }
      } else {
        try {
          this.buttonLoading = true;
          await this.updateWebhook({
            webhookId: this.webhookId,
            payload: {
              url: this.payloadUrl,
              secret: this.secretBase,
              enabled: this.enabled,
              events: selectedEventsArray,
            },
          });
          this.buttonLoading = false;
          this.$router.push({ name: 'store-settings-webhooks' });
          this.$toast.success(this.$t('Webhook successfully updated'));
        } catch (e) {
          this.buttonLoading = false;
        }
      }
    },

    async syncDataFromVuex() {
      const webhookId = this.$route.params.webhook_id;
      this.webhookId = webhookId as string;
      if (webhookId === 'new') {
        this.payloadUrl = '';
        this.secretBase = await this.generateSecret();
        this.enabled = true;
        this.eventsSelected = this.webhookTypes.map((item: WebhookTypes, index: number) => {
          return {
            title: item.title,
            value: item.value,
            checked: index === 0,
          };
        });
      } else {
        const webhook = this.webhookList.filter((item: WebhookResponse) => item.id === webhookId);
        if (webhook.length) {
          this.payloadUrl = webhook[0].url;
          this.secretBase = webhook[0].secret;
          this.enabled = !!webhook[0].enabled;
          this.eventsSelected = this.webhookTypes.map((item: WebhookTypes) => {
            return {
              title: item.title,
              value: item.value,
              checked: !!webhook[0].events.find((elem: string) => elem === item.value),
            };
          });
        }
      }
    },

    ...mapActions('stores', [
      'loadWebhooks',
      'generateSecret',
      'createWebhook',
      'updateWebhook',
    ]),
  },
});
