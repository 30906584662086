
import { defineComponent } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

export default defineComponent({
  components: {
    UiSelect,
    UiButtonLoading,
  },

  emits: ['onSubmit'],

  data() {
    return {
      selectedCurrency: '',
      buttonLoading: false,
    };
  },

  computed: {
    currenciesOptions(): { title: string, value: string }[] {
      return [
        {
          title: 'All Currencies',
          value: '',
        },
        ...this.currencies.map((item: { currencyId: string, walletId: string; }) => {
          return {
            title: `${item.currencyId.split('.')[0]} (${item.currencyId.split('.')[1]})`,
            value: `${item.currencyId}:${item.walletId}`,
          };
        }),
      ];
    },

    selectedCurrencyOption: {
      get(): string {
        return this.selectedCurrency;
      },

      set(value: string) {
        this.selectedCurrency = value;
      },
    },

    selectedCurrencyId(): string {
      return this.selectedCurrencyOption?.split(':')[0];
    },

    selectedWalletId(): string {
      return this.selectedCurrencyOption?.split(':')[1];
    },

    ...mapGetters('wallets', {
      currencies: 'currencies',
      walletBalance: 'walletBalance',
    }),
    ...mapState('wallets', {
      wallets: 'wallets',
    }),
  },

  methods: {
    async withdrawalsRequestHandler() {
      try {
        this.buttonLoading = true;
        await this.withdrawalsRequest({
          currencyId: this.selectedCurrencyId,
        });
        this.buttonLoading = false;
        this.$toast.success(this.$t('The request has been successfully sent'));
        this.$emit('onSubmit');
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    ...mapActions('wallets', ['withdrawalsRequest']),
  },
});
