import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col gap-6" }
const _hoisted_2 = {
  key: 1,
  class: "text-3xl font-600"
}
const _hoisted_3 = { class: "grid gap-6 grid-cols-[6fr,3fr] <laptop-lg:grid-cols-[6fr,4fr] <tablet:grid-cols-[1fr]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_skeleton_box = _resolveComponent("ui-skeleton-box")!
  const _component_ui_alert = _resolveComponent("ui-alert")!
  const _component_balance_chart = _resolveComponent("balance-chart")!
  const _component_processing_wallets = _resolveComponent("processing-wallets")!
  const _component_history_data_block = _resolveComponent("history-data-block")!
  const _component_recent_transactions = _resolveComponent("recent-transactions")!
  const _component_recent_invoices = _resolveComponent("recent-invoices")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_ui_skeleton_box, {
          key: 0,
          width: "100%",
          height: "36px"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.storeName), 1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.processingWallets, (wallet, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        (!wallet.isEnoughBalance)
          ? (_openBlock(), _createBlock(_component_ui_alert, {
              key: 0,
              class: "bg-red-500 text-white",
              modelValue: _ctx.showAlert[wallet.blockchain],
              "onUpdate:modelValue": ($event: any) => ((_ctx.showAlert[wallet.blockchain]) = $event)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('dashboardAlertNotice',
            {
              blockchain: `${_ctx.capitalizeFirstLetter(wallet.blockchain)}`,
              nativeToken: `${_ctx.nativeToken(wallet.blockchain)}`
            }
          )), 1)
              ]),
              _: 2
            }, 1032, ["modelValue", "onUpdate:modelValue"]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_balance_chart, { "store-selected": _ctx.getStoreSelected }, null, 8, ["store-selected"]),
      _createVNode(_component_processing_wallets, { "store-selected": _ctx.getStoreSelected }, null, 8, ["store-selected"])
    ]),
    _createVNode(_component_history_data_block, { "store-selected": _ctx.getStoreSelected }, null, 8, ["store-selected"]),
    _createVNode(_component_recent_transactions, { "store-selected": _ctx.getStoreSelected }, null, 8, ["store-selected"]),
    _createVNode(_component_recent_invoices, { "store-selected": _ctx.getStoreSelected }, null, 8, ["store-selected"])
  ]))
}