
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import UiSkeletonBox from '@/components/ui/UiSkeletonBox.vue';

export default defineComponent({
  props: {
    storeSelected: {
      type: String,
    },
  },

  components: {
    UiSkeletonBox,
  },

  data() {
    return {
      tableColumns: [
        {
          label: 'Date',
          field: 'date',
          textAlign: 'text-left',
        },
        {
          label: 'Invoice Id',
          field: 'id',
          textAlign: 'text-left',
        },
        {
          label: 'Status',
          field: 'status',
          textAlign: 'text-center',
        },
        {
          label: 'Amount',
          field: 'amount',
          textAlign: 'text-right',
        },
      ],
      loading: false,
    };
  },

  computed: {
    ...mapState('dashboard', {
      recentInvoices: 'recentInvoices',
      isRecentInvoicesLoaded: 'isRecentInvoicesLoaded',
    }),
  },

  watch: {
    storeSelected() {
      this.loading = true;
      this.loadRecentInvoicesData();
    },
  },

  created() {
    if (!this.isRecentInvoicesLoaded) {
      this.loading = true;
    }
    this.loadRecentInvoicesData();
  },

  methods: {
    async loadRecentInvoicesData() {
      try {
        await this.loadRecentInvoices();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    ...mapActions('dashboard', ['loadRecentInvoices']),
  },
});
