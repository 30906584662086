
import {defineComponent} from 'vue';
import {mapActions} from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

export default defineComponent({
  components: {
    UiButtonLoading,
  },

  data() {
    return {
      email: '',
      buttonLoading: false,
      emailSent: false,
    };
  },

  methods: {
    async reset() {
      try {
        this.buttonLoading = true;
        await this.resetPassword({email: this.email});
        this.buttonLoading = false;
        this.emailSent = true;
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    ...mapActions('auth', ['resetPassword']),
  },
});
