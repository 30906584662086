import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a2053e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account-change-otp" }
const _hoisted_2 = { class: "form-inputs" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { for: "secret" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { for: "secret" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { for: "secret" }
const _hoisted_9 = { class: "form-inputs" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_qrcode = _resolveComponent("vue-qrcode")!
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isOTPEnabled())
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          class: "form",
          onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.enableAccountOTP && _ctx.enableAccountOTP(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('Scan this code with your OTP application')), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_vue_qrcode, {
                  value: _ctx.qrCodeValue,
                  width: 300,
                  scale: 4,
                  quality: 1
                }, null, 8, ["value"])
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('or enter this secret phrase manually')), 1),
              _withDirectives(_createElementVNode("input", {
                required: "",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.base32Value) = $event)),
                class: "input my-2 w-full",
                type: "text",
                name: "secretCode",
                readonly: true
              }, null, 512), [
                [_vModelText, _ctx.base32Value]
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('OTP code from application (6 digits)')), 1),
              _withDirectives(_createElementVNode("input", {
                required: "",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.answer) = $event)),
                class: "input my-2 w-full",
                type: "text",
                name: "answer",
                minlength: "6",
                maxlength: "6"
              }, null, 512), [
                [_vModelText, _ctx.answer]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_ui_button_loading, {
              class: "button button-primary my-4 p-2",
              type: "submit",
              loading: _ctx.buttonLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Enable OTP')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ], 32))
      : _createCommentVNode("", true),
    (_ctx.isOTPEnabled())
      ? (_openBlock(), _createElementBlock("form", {
          key: 1,
          class: "form",
          onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.disableAccountOTP && _ctx.disableAccountOTP(...args)), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.$t('OTP now is Enabled. To disable it please enter code below')), 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                required: "",
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.answer) = $event)),
                class: "input my-2 w-full",
                type: "text",
                name: "answer",
                minlength: "6",
                maxlength: "6",
                placeholder: _ctx.$t('OTP code from application (6 digits)')
              }, null, 8, _hoisted_12), [
                [_vModelText, _ctx.answer]
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_ui_button_loading, {
              class: "button button-primary my-4 p-2",
              type: "submit",
              loading: _ctx.buttonLoading
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('Disable OTP')), 1)
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ], 32))
      : _createCommentVNode("", true)
  ]))
}