import { api } from '@/api/Api';
import { Token, AxiosBaseResponse } from '@/api/types';
import {
  LoginUserRequest,
  LoginUserResponse,
  OtpRequest,
  RegisterUserRequest,
  ResetPasswordConfirmRequest,
  ResetPasswordRequest,
  ValidateTokenRequest,
} from './types';

const urls = {
  login: '/auth/login',
  register: '/users/register',
  reset: '/users/password/reset',
  resetConfirm: '/users/password/reset-confirm',
  validateToken: '/users/password/validate-token',
  logout: '/auth/logout',
  validateAccessToken: '/users/user',
  otp: '/auth/otp',
};

export default class AuthService {
  public static async loginUser(payload: LoginUserRequest): Promise<AxiosBaseResponse<LoginUserResponse>> {
    return api.post(urls.login, payload);
  }

  public static async registerUser(payload: RegisterUserRequest): Promise<AxiosBaseResponse<void>> {
    return api.post(urls.register, payload);
  }

  public static async resetPassword(payload: ResetPasswordRequest): Promise<AxiosBaseResponse<void>> {
    return api.post(urls.reset, payload);
  }

  public static async resetPasswordConfirm(payload: ResetPasswordConfirmRequest): Promise<AxiosBaseResponse<void>> {
    return api.patch(urls.resetConfirm, payload);
  }

  public static async validateToken(payload: ValidateTokenRequest): Promise<AxiosBaseResponse<void>> {
    return api.post(urls.validateToken, payload);
  }

  public static async logoutUser(token: Token): Promise<AxiosBaseResponse<void>> {
    return api.post(urls.logout, null, token);
  }

  public static async validateAccessToken(token: Token): Promise<AxiosBaseResponse<void>> {
    return api.get(urls.validateAccessToken, null, token);
  }

  public static async checkOTP(payload: OtpRequest): Promise<AxiosBaseResponse<LoginUserResponse>> {
    return api.post(urls.otp, payload);
  }
}
