import { RouteRecordRaw } from 'vue-router';
import { AppLayout } from '@/layouts/types';
import HomeView from '@/views/HomeView.vue';
import LoginView from '@/views/LoginView.vue';
import RegisterView from '@/views/RegisterView.vue';
import LostPasswordView from '@/views/LostPasswordView.vue';
import ResetPasswordView from '@/views/ResetPasswordView.vue';
import DashboardView from '@/views/DashboardView.vue';
import WalletsWithdrawalsView from '@/views/wallets/WalletsWithdrawalsView.vue';
import WithdrawalsSettingsView from '@/views/wallets/WithdrawalsSettingsView.vue';
import StoreSettingsView from '@/views/storeSettings/StoreSettingsView.vue';
import CreateStoreView from '@/views/CreateStoreView.vue';
import GeneralView from '@/views/storeSettings/GeneralView.vue';
import RatesView from '@/views/storeSettings/RatesView.vue';
import ApiKeysView from '@/views/storeSettings/ApiKeysView.vue';
import WebhooksView from '@/views/storeSettings/WebhooksView.vue';
import WebhookItemView from '@/views/storeSettings/WebhookItemView.vue';
import WebhookTestView from '@/views/storeSettings/WebhookTestView.vue';
import InvoicesView from '@/views/invoices/InvoicesView.vue';
import InvoiceView from '@/views/invoices/InvoiceView.vue';
import InvoiceDetailsView from '@/views/invoices/InvoiceDetailsView.vue';
import InvoiceCreateView from '@/views/invoices/InvoiceCreateView.vue';
import AccountView from '@/views/AccountView.vue';
import PageNotFoundView from '@/views/PageNotFoundView.vue';
import auth from '@/router/middlewares/auth';
import guest from '@/router/middlewares/guest';
import AccountGeneralView from '@/views/account/AccountGeneralView.vue';
import AccountOTPView from '@/views/account/AccountOTPView.vue';

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      middleware: [guest, auth],
      layout: AppLayout.DEFAULT,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFoundView,
    meta: {
      layout: AppLayout.DEFAULT,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      middleware: [guest],
      layout: AppLayout.AUTH,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: {
      middleware: [guest],
      layout: AppLayout.AUTH,
    },
  },
  {
    path: '/lost-password',
    name: 'lost-password',
    component: LostPasswordView,
    meta: {
      middleware: [guest],
      layout: AppLayout.AUTH,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: ResetPasswordView,
    meta: {
      middleware: [guest],
      layout: AppLayout.AUTH,
    },
    props: (route) => ({ token: route.query.token }),
  },
  {
    path: '/account',
    name: 'account',
    component: AccountView,
    redirect: (to) => ({
      name: 'account-general',
    }),
    children: [
      {
        path: '/account/general',
        name: 'account-general',
        component: AccountGeneralView,
      },
      {
        path: '/account/otp',
        name: 'account-otp',
        component: AccountOTPView,
      },
    ],
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/wallets/withdrawals',
    name: 'wallets-withdrawals',
    component: WalletsWithdrawalsView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/wallets/:wallet_id/withdrawals/setting',
    name: 'wallets-withdrawals-settings',
    component: WithdrawalsSettingsView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/create',
    name: 'store-create',
    component: CreateStoreView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/settings',
    redirect: (to) => ({
      name: 'store-settings-general',
      params: { store_id: to.params.store_id },
    }),
    name: 'store-settings',
    component: StoreSettingsView,
    children: [
      {
        path: '/stores/:store_id/settings/general',
        name: 'store-settings-general',
        component: GeneralView,
      },
    ],
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/settings/general',
    name: 'store-settings-general',
    component: GeneralView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/settings/rates',
    name: 'store-settings-rates',
    component: RatesView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/settings/api-keys',
    name: 'store-settings-api-keys',
    component: ApiKeysView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/settings/webhooks',
    name: 'store-settings-webhooks',
    component: WebhooksView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/settings/webhooks/:webhook_id',
    name: 'store-settings-webhook-item',
    component: WebhookItemView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/settings/webhooks/:webhook_id/test',
    name: 'store-settings-webhook-test',
    component: WebhookTestView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/invoices',
    name: 'invoices',
    component: InvoicesView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/invoices/:invoice_id',
    name: 'invoice',
    component: InvoiceView,
    meta: {
      middleware: [guest, auth],
      layout: AppLayout.INVOICE,
    },
  },
  {
    path: '/stores/:store_id/invoices/:invoice_id',
    name: 'invoice-details',
    component: InvoiceDetailsView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
  {
    path: '/stores/:store_id/invoices/create',
    name: 'invoice-create',
    component: InvoiceCreateView,
    meta: {
      middleware: [auth],
      layout: AppLayout.ADMIN,
    },
  },
];
