import { ActionTree, Module, MutationTree, GetterTree } from 'vuex';
import { useToast } from 'vue-toastification';
import { RootState } from '@/store/types';
import DictionariesService from '@/services/DictionariesService';
import { pluralize } from '@/utils';

const toast = useToast();

interface SelectOptions {
  title: string;
  value: string;
}

interface CurrenciesResult {
  code: string;
  contractAddress: string;
  id: string;
  isFiat: boolean;
  name: string;
  precision: number;
}

interface Blockchain {
  name: string;
  title: string;
  importMethods: string[];
  currencies: string[];
  nativeToken: string;
}

interface BlockchainsResult {
  [key: string]: Blockchain;
}

interface DictionariesState {
  dictionaries: RootState;
  isDictionariesLoading: boolean;
  currencies: CurrenciesResult[];
  rateSources: SelectOptions[];
  webhookTypes: SelectOptions[];
  blockchains: BlockchainsResult;
  withdrawalIntervals: number[];
}

const state: DictionariesState = {
  dictionaries: {},
  isDictionariesLoading: false,
  currencies: [],
  rateSources: [],
  webhookTypes: [],
  blockchains: {},
  withdrawalIntervals: [],
};

const getters: GetterTree<DictionariesState, RootState> = {
  blockchainsArray(state) {
    return Object.keys(state.blockchains).sort();
  },

  fiatCurrencies(state) {
    return state.currencies
      .filter((elem) => elem.isFiat)
      .map((item) => ({
        title: item.name,
        value: item.id,
      }));
  },

  cryptoCurrencies(state) {
    return state.currencies
      .filter((elem) => !elem.isFiat)
      .map((item) => ({
        title: `${item.name} (${item.id.split('.')[1]})`,
        value: item.id,
      }));
  },

  cryptoCurrencyPrecision(state) {
    return (id: string) => {
      return state.currencies
        .filter((elem) => !elem.isFiat)
        .reduce((acc, curr) => {
          if (curr.id === id) {
            return curr.precision;
          }
          return acc;
        }, 0);
    };
  },

  nativeToken(state) {
    return (blockchain: string) => {
      return state.blockchains[blockchain]?.nativeToken || '';
    };
  },

  withdrawalIntervals(state) {
    return state.withdrawalIntervals
      .map((item) => {
        if (item === 0) {
          return {
            title: 'never',
            value: item,
          };
        }
        if (item < 60 && item !== 0) {
          return {
            title: pluralize(item, 'minute'),
            value: item,
          };
        }
        return {
          title: pluralize((item / 60), 'hour'),
          value: item,
        };
      });
  },
};

const mutations: MutationTree<DictionariesState> = {
  setDictionaries(state, value: RootState) {
    state.dictionaries = value;
  },

  setIsDictionariesLoading(state, value: boolean) {
    state.isDictionariesLoading = value;
  },

  setCurrencies(state, value: CurrenciesResult[]) {
    state.currencies = value;
  },

  setRateSources(state, value: string[]) {
    state.rateSources = value.map((item) => ({
      title: item,
      value: item,
    }));
  },

  setWebhookTypes(state, value: Record<string, string>) {
    state.webhookTypes = Object.entries(value).map((item) => {
      return {
        title: item[1],
        value: item[0],
      };
    });
  },

  setBlockchains(state, value: BlockchainsResult) {
    state.blockchains = value;
  },

  setWithdrawalIntervals(state, value: number[]) {
    state.withdrawalIntervals = value;
  },
};

const actions: ActionTree<DictionariesState, RootState> = {
  async loadDictionaries(context) {
    try {
      const { data } = await DictionariesService.getDictionaries();
      const { result } = data;
      context.commit('setDictionaries', result);
      context.commit('setCurrencies', result.currencies);
      context.commit('setRateSources', result.rateSources);
      context.commit('setWebhookTypes', result.webhookTypes);
      context.commit('setBlockchains', result.blockchains);
      context.commit('setWithdrawalIntervals', result.withdrawalIntervals);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },
};

export const dictionaries: Module<DictionariesState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
