
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

export default defineComponent({
  components: {
    UiSelect,
    UiButtonLoading,
  },

  data() {
    return {
      rateSourceSelect: '',
      buttonLoading: false,
    };
  },

  computed: {
    ...mapState('dictionaries', {
      rateSources: 'rateSources',
    }),
    ...mapState('stores', {
      rateSource: 'rateSource',
    }),
  },

  watch: {
    rateSource() {
      this.syncDataFromVuex();
    },
  },

  mounted() {
    this.syncDataFromVuex();
  },

  methods: {
    async updateRateSettings() {
      try {
        this.buttonLoading = true;
        await this.updateRateSource({
          rateSource: this.rateSourceSelect,
        });
        this.buttonLoading = false;
        this.$toast.success(this.$t('Rate source successfully updated'));
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    syncDataFromVuex() {
      this.rateSourceSelect = this.rateSource;
    },

    ...mapActions('stores', ['updateRateSource']),
  },
});
