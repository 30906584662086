import { createApp } from 'vue';
import Toast, { PluginOptions, useToast } from 'vue-toastification';
import useClipboard from 'vue-clipboard3';
import App from './App.vue';
import router from './router';
import store from './store';
import initial from './plugins/initial';
import i18n from './plugins/i18n';
import FontAwesomeIcon from './plugins/fa';
import clickOutside from './directives/clickOutside';
import regexpValidate from './directives/regexpValidate';
// eslint-disable-next-line import/no-unresolved
import 'windi.css';
import 'vue-toastification/dist/index.css';

const toastOptions: PluginOptions = {
  transition: 'Vue-Toastification__fade',
  timeout: 3000,
  draggable: false,
  hideProgressBar: true,
  icon: false,
  toastClassName: 'toast__class',
  bodyClassName: ['toast__class-body'],
};

const app = createApp(App);
app.use(i18n);
app.use(Toast, toastOptions);
app.use(store);
app.use(router);
app.use(initial);
app.component('fa-icon', FontAwesomeIcon);
app.directive('clickOut', clickOutside);
app.directive('regexp-validate', regexpValidate);
app.mount('#app');

app.config.globalProperties.$toast = useToast();
app.config.globalProperties.$copyText = useClipboard();
