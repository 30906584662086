import { useToast } from 'vue-toastification';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import AccountService from '@/services/AccountService';
import {
  ChangeAccountPasswordRequest,
  OTPDisableRequest,
  OTPEnableRequest,
} from '@/services/AccountService/types';

const toast = useToast();

interface Account {
  id: string;
  email: string;
  otp_enabled: boolean;
}

interface AccountState {
  account: Account;
}

const state: AccountState = {
  account: {
    id: '',
    email: '',
    otp_enabled: true,
  },
};

const getters: GetterTree<AccountState, RootState> = {
  getAccount(state): Account {
    return state.account;
  },

  isOTPEnabled(state): boolean {
    return state.account.otp_enabled;
  },
};

const mutations: MutationTree<AccountState> = {
  setAccount(state, account) {
    state.account = account;
  },
};

const actions: ActionTree<AccountState, RootState> = {
  async changePassword(context, payload: ChangeAccountPasswordRequest) {
    try {
      const { data } = await AccountService.changePassword(
        payload,
        context.rootGetters['auth/accessToken'],
      );
      const { result } = data;
      context.commit('auth/setAccessToken', result.token, { root: true });
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async loadAccount(context) {
    try {
      const { data } = await AccountService.getAccount(context.rootGetters['auth/accessToken']);
      context.commit('setAccount', data);
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async enableOTP(context, payload: OTPEnableRequest) {
    try {
      await AccountService.enableOtp(payload, context.rootGetters['auth/accessToken']);
      await context.dispatch('loadAccount');
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },

  async disableOTP(context, payload: OTPDisableRequest) {
    try {
      await AccountService.disableOtp(payload, context.rootGetters['auth/accessToken']);
      await context.dispatch('loadAccount');
    } catch (e) {
      toast.error(e.message);
      throw e;
    }
  },
};

export const account: Module<AccountState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
