import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2decaaf9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-2xl font-600 mb-6" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = {
  for: "ratesSelect",
  class: "self-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('Rates')), 1),
    _createElementVNode("form", {
      class: "form",
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateRateSettings && _ctx.updateRateSettings(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Preferred Price Source')), 1),
        (_ctx.rateSources.length && _ctx.rateSource)
          ? (_openBlock(), _createBlock(_component_ui_select, {
              key: 0,
              class: "h-42px",
              id: "ratesSelect",
              modelValue: _ctx.rateSourceSelect,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.rateSourceSelect) = $event)),
              options: _ctx.rateSources
            }, null, 8, ["modelValue", "options"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ui_button_loading, {
        class: "button button-primary py-2 px-4 self-start",
        type: "submit",
        loading: _ctx.buttonLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Save')), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ], 32)
  ]))
}