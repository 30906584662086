import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "font-600 px-4 py-3 flex items-center justify-between gap-4 rounded-sm shadow-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default"),
        _createVNode(_component_fa_icon, {
          icon: "xmark",
          size: "lg",
          class: "cursor-pointer opacity-80 hover:opacity-100",
          onClick: _ctx.closeHandler
        }, null, 8, ["onClick"])
      ]))
    : _createCommentVNode("", true)
}