
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    id: {
      type: String,
    },
    checked: {
      type: Boolean,
    },
  },

  emits: ['switched'],

  methods: {
    changeHandler() {
      this.$emit('switched');
    },
  },
});
