import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bbed961"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sidebar_menu = _resolveComponent("sidebar-menu")!
  const _component_store_settings_menu = _resolveComponent("store-settings-menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_sidebar_menu, { "store-settings-active": _ctx.showStoreSettings }, null, 8, ["store-settings-active"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showStoreSettings)
        ? (_openBlock(), _createBlock(_component_store_settings_menu, { key: 0 }))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}