
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import UiModal from '@/components/ui/UiModal.vue';
import WalletsWithdrawals from '@/components/withdrawals/WalletsWithdrawals.vue';
import RequestWithdrawalForm from '@/components/withdrawals/RequestWithdrawalForm.vue';
import CompletedWithdrawals from '@/components/withdrawals/CompletedWithdrawals.vue';

export default defineComponent({
  components: {
    UiModal,
    WalletsWithdrawals,
    RequestWithdrawalForm,
    CompletedWithdrawals,
  },

  data() {
    return {
      showModal: false,
    };
  },

  computed: {
    ...mapState('wallets', {
      wallets: 'wallets',
    }),
  },

  methods: {
    showRequestForm() {
      this.showModal = true;
    },

    withdrawalFormSubmit() {
      this.showModal = false;
    },
  },
});
