
import { defineComponent } from 'vue';
import SidebarMenu from '@/components/SidebarMenu.vue';
import StoreSettingsMenu from '@/components/StoreSettingsMenu.vue';

export default defineComponent({
  components: {
    SidebarMenu,
    StoreSettingsMenu,
  },

  computed: {
    showStoreSettings() {
      const input = 'settings';
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => this.$route.path.split('/').indexOf(path) === 3);
    },
  },
});
