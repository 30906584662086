import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '@/router/routes';
import store from '@/store';
import { StoreSelectOptionType } from '@/store/stores';
import middlewarePipeline from '@/router/middlewarePipeline';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const middleware = to.meta.middleware;

  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

router.beforeResolve(async (to, from, next) => {
  if (to.params.store_id) {
    if (!store.state.stores.storeOptions.length) {
      await store.dispatch('stores/loadStores');
    }
    const isStore = await store.state.stores.storeOptions
      .find((item: StoreSelectOptionType) => item.value === to.params.store_id);
    if (isStore) {
      store.commit('stores/setStoreSelected', to.params.store_id);
      next();
    } else {
      next({ name: 'store-create' });
    }
    return;
  }
  next();
});

export default router;
