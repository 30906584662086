
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    id: {
      type: String,
    },
    label: {
      type: String,
    },
    modelValue: {
      type: Boolean,
    },
  },

  emits: ['checked', 'update:modelValue'],

  methods: {
    checkboxHandler(e: Event) {
      const check = (e.target as HTMLInputElement).checked;
      this.$emit('update:modelValue', check);
      this.$emit('checked');
    },
  },
});
