
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import UiLoading from '@/components/ui/UiLoading.vue';

export default defineComponent({
  components: {
    UiLoading,
  },

  data() {
    return {
      tableColumns: [
        {
          label: 'Url',
          field: 'url',
          textAlign: 'text-left',
        },
        {
          label: 'Actions',
          field: 'actions',
          textAlign: 'text-right',
        },
      ],
      loading: false,
    };
  },

  computed: {
    ...mapState('stores', ['webhookList']),
  },

  created() {
    this.loadWebhooks();
  },

  methods: {
    createWebhookHandler() {
      this.$router.push(
        { name: 'store-settings-webhook-item', params: { webhook_id: 'new' } },
      );
    },

    testWebhookHandler(webhookId: string) {
      this.$router.push(
        { name: 'store-settings-webhook-test', params: { webhook_id: webhookId } },
      );
    },

    modifyWebhookHandler(webhookId: string) {
      this.$router.push(
        { name: 'store-settings-webhook-item', params: { webhook_id: webhookId } },
      );
    },

    async deleteWebhookHandler(webhookId: string) {
      this.loading = true;
      try {
        await this.deleteWebhook(webhookId);
        await this.loadWebhooks();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    ...mapActions('stores', ['loadWebhooks', 'deleteWebhook']),
  },
});
