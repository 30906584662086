
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    textToCopy: {
      type: String,
      required: true,
    },

    hoverHint: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      timer: 0 as ReturnType<typeof setTimeout>,
      check: false,
      hoverText: 'Copy to clipboard',
    };
  },

  methods: {
    clickHandler() {
      this.$copyText.toClipboard(this.textToCopy);
      this.hoverText = 'Copied';
      this.check = true;

      this.timer = setTimeout(() => {
        this.hoverText = 'Copy to clipboard';
        this.check = false;
      }, 1000);
    },
  },

  beforeUnmount() {
    clearTimeout(this.timer);
  },
});
