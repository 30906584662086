
import { defineComponent } from 'vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { capitalizeFirstLetter } from '@/utils';
import UiSkeletonBox from '@/components/ui/UiSkeletonBox.vue';
import BalanceChart from '@/components/dashboard/BalanceChart.vue';
import HistoryDataBlock from '@/components/dashboard/HistoryDataBlock.vue';
import RecentTransactions from '@/components/dashboard/RecentTransactions.vue';
import RecentInvoices from '@/components/dashboard/RecentInvoices.vue';
import ProcessingWallets from '@/components/dashboard/ProcessingWallets.vue';
import UiAlert from '@/components/ui/UiAlert.vue';

export default defineComponent({
  components: {
    UiSkeletonBox,
    BalanceChart,
    HistoryDataBlock,
    RecentTransactions,
    RecentInvoices,
    ProcessingWallets,
    UiAlert,
  },

  data() {
    return {
      loading: false,
      showAlert: {} as Record<string, boolean>,
    };
  },

  computed: {
    ...mapState('stores', {
      storeName: 'name',
    }),
    ...mapGetters('stores', {
      getStoreSelected: 'getStoreSelected',
    }),
    ...mapGetters('dashboard', {
      processingWallets: 'processingWallets',
    }),
    ...mapGetters('dictionaries', {
      nativeToken: 'nativeToken',
    }),
  },

  watch: {
    getStoreSelected() {
      this.loading = true;
      this.loadStoreData();
    },
    processingWallets() {
      this.initialAlertState();
    },
  },

  created() {
    this.setIsStoreLoading(true);
    if (!this.storeName) {
      this.loading = true;
    }
    this.loadStoreData();
  },

  methods: {
    async loadStoreData() {
      try {
        await this.loadStoreById();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    initialAlertState() {
      this.showAlert = this.processingWallets
        .reduce((acc: Record<string, boolean>, curr: { blockchain: string; }) => {
          return {
            ...acc,
            [curr.blockchain]: true,
          };
        }, {} as Record<string, boolean>);
    },

    capitalizeFirstLetter,

    ...mapMutations('stores', ['setIsStoreLoading']),
    ...mapActions('stores', ['loadStoreById']),
  },
});
