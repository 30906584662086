import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7afe0ba0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-3xl font-600 mb-6" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = {
  for: "createStoreName",
  "data-required": "",
  class: "self-start"
}
const _hoisted_4 = { class: "form-group" }
const _hoisted_5 = {
  for: "createStoreCurrency",
  "data-required": "",
  class: "self-start"
}
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = {
  for: "createStorePriceSource",
  "data-required": "",
  class: "self-start"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('Create a new store')), 1),
    _createElementVNode("form", {
      class: "form",
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.createStoreHandler && _ctx.createStoreHandler(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('Name')), 1),
        _withDirectives(_createElementVNode("input", {
          required: "",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.storeName) = $event)),
          id: "createStoreName",
          class: "input",
          type: "text"
        }, null, 512), [
          [_vModelText, _ctx.storeName]
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('Default currency')), 1),
        (_ctx.fiatCurrencies.length)
          ? (_openBlock(), _createBlock(_component_ui_select, {
              key: 0,
              id: "createStoreCurrency",
              class: "max-w-90px h-42px",
              modelValue: _ctx.currencySelect,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.currencySelect) = $event)),
              options: _ctx.fiatCurrencies
            }, null, 8, ["modelValue", "options"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('Preferred Price Source')), 1),
        (_ctx.rateSources.length)
          ? (_openBlock(), _createBlock(_component_ui_select, {
              key: 0,
              id: "createStorePriceSource",
              class: "max-w-250px h-42px",
              modelValue: _ctx.rateSourceSelect,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.rateSourceSelect) = $event)),
              options: _ctx.rateSources
            }, null, 8, ["modelValue", "options"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ui_button_loading, {
        class: "button button-primary py-2 px-4 self-start",
        type: "submit",
        loading: _ctx.buttonLoading
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('Create')), 1)
        ]),
        _: 1
      }, 8, ["loading"])
    ], 32)
  ]))
}