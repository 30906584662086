import { api } from '@/api/Api';
import { AxiosBaseResponse, Token } from '@/api/types';
import {
  CreateWalletRequest,
  WalletsResponse,
  AddressRequest,
  MnemonicRequest,
  WithdrawalsSettingsResponse,
  UpdateWithdrawalsSettingsRequest,
  WalletWithdrawalsRequest,
  CompletedWithdrawalsResponse,
} from './types';

const urls = {
  stores: '/stores',
  mnemonic: '/mnemonic',
  getStore(storeId: string): string {
    return `${this.stores}/${storeId}`;
  },
};

export default class WalletsService {
  public static async getWallets(storeId: string, token: Token): Promise<AxiosBaseResponse<WalletsResponse[]>> {
    return api.get(`${urls.getStore(storeId)}/wallets`, null, token);
  }

  public static async createWallets(
    storeId: string,
    payload: CreateWalletRequest,
    token: Token,
  ): Promise<AxiosBaseResponse<WalletsResponse[]>> {
    return api.post(`${urls.getStore(storeId)}/wallets`, payload, token);
  }

  public static async importMnemonic(
    storeId: string,
    blockchain: string,
    payload: MnemonicRequest,
    token: Token,
  ): Promise<AxiosBaseResponse<WalletsResponse>> {
    return api.put(`${urls.getStore(storeId)}/wallets/${blockchain}/mnemonic`, payload, token);
  }

  public static async importAddress(
    storeId: string,
    blockchain: string,
    payload: AddressRequest,
    token: Token,
  ): Promise<AxiosBaseResponse<WalletsResponse>> {
    return api.put(`${urls.getStore(storeId)}/wallets/${blockchain}/address`, payload, token);
  }

  public static async generateMnemonic(): Promise<AxiosBaseResponse<string>> {
    return api.get(`${urls.mnemonic}`);
  }

  public static getWithdrawalsSettings(
    storeId: string,
    walletId: string,
    token: Token,
  ): Promise<AxiosBaseResponse<WithdrawalsSettingsResponse>> {
    return api.get(`${urls.getStore(storeId)}/wallets/${walletId}/settings`, null, token);
  }

  public static updateWithdrawalsSettings(
    storeId: string,
    walletId: string,
    payload: UpdateWithdrawalsSettingsRequest,
    token: Token,
  ): Promise<AxiosBaseResponse<void>> {
    return api.put(`${urls.getStore(storeId)}/wallets/${walletId}/settings`, payload, token);
  }

  public static withdrawalsRequest(
    storeId: string,
    payload: WalletWithdrawalsRequest,
    token: Token,
  ): Promise<AxiosBaseResponse<void>> {
    return api.post(`${urls.getStore(storeId)}/wallets/withdrawals`, payload, token);
  }

  public static getCompletedWithdrawals(
    storeId: string,
    params: Record<string, string>,
    token: Token,
  ): Promise<AxiosBaseResponse<CompletedWithdrawalsResponse[]>> {
    return api.get(`${urls.getStore(storeId)}/wallets/withdrawals`, params, token);
  }

  public static compareWallets(a: WalletsResponse, b: WalletsResponse) {
    if (a.address.length < b.address.length) {
      return 1;
    }
    if (a.address.length > b.address.length) {
      return -1;
    }
    return 0;
  }
}
