import { createStore } from 'vuex';
import { app } from '@/store/app';
import { auth } from '@/store/auth';
import { stores } from '@/store/stores';
import { dictionaries } from '@/store/dictionaries';
import { wallets } from '@/store/wallets';
import { invoices } from '@/store/invoices';
import { dashboard } from '@/store/dashboard';
import { account } from '@/store/account';
import _cloneDeep from 'lodash/cloneDeep';
import _forOwn from 'lodash/forOwn';

const storeModules = {
  app,
  auth,
  stores,
  dictionaries,
  wallets,
  invoices,
  dashboard,
  account,
};

const initialStoreModules = _cloneDeep(storeModules);

interface Excluded {
  modules?: string[];
  states?: Partial<typeof storeModules>;
}

const store = createStore({
  modules: storeModules,

  mutations: {
    resetStore(state) {
      _forOwn(initialStoreModules, (value, key) => {
        Object.assign(state[key], _cloneDeep(value.state));
      });
    },

    resetStoreExclude(state, excluded: Excluded) {
      _forOwn(initialStoreModules, (value, key) => {
        const excludedStates = Object.keys(excluded?.states || {})
          .find((elem) => elem === key);

        const excludedModules = excluded?.modules?.find((elem) => elem === key);

        if (excludedStates) {
          Object.keys(state[excludedStates]).forEach((item) => {
            const exc = Object.values(excluded?.states || {})
              .map((elem) => (elem as Array<string>).find((unit) => unit === item))
              .filter((n) => n);

            if (exc[0]) {
              state[excludedStates][item] = _cloneDeep(state[excludedStates][item]);
            } else {
              state[excludedStates][item] = _cloneDeep(value.state?.[item as keyof typeof value.state]);
            }
          });
        } else if (!excludedModules) {
          Object.assign(state[key], _cloneDeep(value.state));
        }
      });
    },
  },
});

export default store;

window.addEventListener('storage', async (e) => {
  switch (e.key) {
    case 'access_token':
      await store.dispatch('auth/validateAccessToken', {
        token: e.newValue || '',
      });
      break;
    default:
  }
});
