import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb85de74"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "api-keys-wrapper" }
const _hoisted_2 = { class: "mb-6" }
const _hoisted_3 = { class: "flex items-center justify-between mb-3" }
const _hoisted_4 = { class: "text-2xl font-600" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "relative table-h-scroll" }
const _hoisted_7 = {
  key: 0,
  class: "w-full"
}
const _hoisted_8 = { class: "border-b" }
const _hoisted_9 = {
  key: 1,
  class: "break-all flex items-center gap-2"
}
const _hoisted_10 = {
  key: 2,
  class: "flex justify-end items-center gap-6"
}
const _hoisted_11 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_ui_switcher = _resolveComponent("ui-switcher")!
  const _component_ui_copy_to_clipboard = _resolveComponent("ui-copy-to-clipboard")!
  const _component_ui_loading = _resolveComponent("ui-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('Api Keys')), 1),
        _createElementVNode("button", {
          class: "button button-primary px-4 py-2 flex items-center gap-2",
          disabled: _ctx.loading,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.createApiKeyHandler && _ctx.createApiKeyHandler(...args)))
        }, [
          _createVNode(_component_fa_icon, { icon: "plus" }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('Create Api Key')), 1)
        ], 8, _hoisted_5)
      ]),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('Use the API by including the following HTTP Header in your requests')), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.apiKeyList.length)
        ? (_openBlock(), _createElementBlock("table", _hoisted_7, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, i) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: i,
                    scope: "col",
                    class: _normalizeClass(["p-2 align-top", column.textAlign])
                  }, [
                    _createElementVNode("div", null, _toDisplayString(column.label), 1)
                  ], 2))
                }), 128))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.apiKeyList, (item, i) => {
                return (_openBlock(), _createElementBlock("tr", {
                  key: i,
                  class: "hover:bg-white"
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, j) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: j,
                      class: _normalizeClass(["px-2 py-3", column.textAlign])
                    }, [
                      (column.field === 'enabled')
                        ? (_openBlock(), _createBlock(_component_ui_switcher, {
                            key: 0,
                            checked: item.enabled,
                            onSwitched: ($event: any) => (_ctx.switchHandler(item.id, item.enabled))
                          }, null, 8, ["checked", "onSwitched"]))
                        : (column.field === 'apikey')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createElementVNode("span", null, _toDisplayString(item.key), 1)
                            ]))
                          : (column.field === 'actions')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createVNode(_component_ui_copy_to_clipboard, {
                                  "text-to-copy": item.key
                                }, null, 8, ["text-to-copy"]),
                                _createElementVNode("button", {
                                  class: "button button-error py-1 px-2",
                                  onClick: ($event: any) => (_ctx.deleteApiKeyHandler(item.id))
                                }, _toDisplayString(_ctx.$t('Delete')), 9, _hoisted_11)
                              ]))
                            : _createCommentVNode("", true)
                    ], 2))
                  }), 128))
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_ui_loading, { loading: _ctx.loading }, null, 8, ["loading"])
    ])
  ]))
}