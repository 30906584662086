
import { defineComponent, PropType } from 'vue';
import { SelectOptionType } from '@/components/types';

const __default__ = defineComponent({
  props: {
    options: {
      type: Array as PropType<SelectOptionType[]>,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
    extraOption: {
      type: String,
      required: false,
    },
  },

  emits: ['change'],

  data() {
    return {
      open: false,
      focus: false,
      dropdownSelectHeight: '',
    };
  },

  computed: {
    selectedOption(): SelectOptionType {
      return this.options.filter((item) => item.value === this.selected)[0];
    },
  },

  mounted() {
    this.dropdownSelectHeight = `${
      (this.$refs.dropdownSelect as HTMLElement).clientHeight
    }px`;
  },

  methods: {
    selectBlurHandler() {
      this.open = false;
      this.focus = false;
    },

    selectedClickHandler() {
      this.open = !this.open;
      this.focus = true;
    },

    optionClickHandler(value: string) {
      this.open = false;
      this.focus = false;
      this.$emit('change', value);
    },

    extraOptionClickHandler() {
      this.open = false;
      this.focus = false;
      this.$emit('change', 'extra');
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "42733b98": (_ctx.dropdownSelectHeight)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__