
import { defineComponent } from 'vue';
import {
  mapState, mapGetters, mapMutations, mapActions,
} from 'vuex';
import StoreDropdownSelect from '@/components/StoreDropdownSelect.vue';
import IBurgerMenu from '@/components/icon/IBurgerMenu.vue';
import LogoNav from '@/components/LogoNav.vue';

export default defineComponent({
  props: {
    storeSettingsActive: {
      type: Boolean,
    },
  },

  components: {
    StoreDropdownSelect,
    IBurgerMenu,
    LogoNav,
  },

  data() {
    return {
      isShowAccountMenu: false,
      isShowSidebar: false,
      sidebarHeaderMenuHeight: '124px',
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    invoicesLinkActive() {
      const input = 'invoices';
      return this.$route.path.split('/').find((item) => item === input);
    },

    withdrawalsLinkActive() {
      const input = 'withdrawals';
      return this.$route.path.split('/').find((item) => item === input);
    },

    ...mapState('stores', {
      storeOptions: 'storeOptions',
    }),
    ...mapState('wallets', ['wallets']),
    ...mapGetters('stores', {
      getStoreSelected: 'getStoreSelected',
    }),
  },

  watch: {
    windowWidth() {
      this.onSidebarHeaderMenuHeight();
    },
  },

  mounted() {
    this.onSidebarHeaderMenuHeight();

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    onSidebarHeaderMenuHeight() {
      this.sidebarHeaderMenuHeight = `${(this.$refs.sidebarHeaderMenu as HTMLElement).clientHeight}px`;
    },

    showAccountMenu(e: Event) {
      e.stopPropagation();
      this.isShowAccountMenu = !this.isShowAccountMenu;
    },

    closeAccountMenu() {
      this.isShowAccountMenu = false;
    },

    showSidebar() {
      this.isShowSidebar = !this.isShowSidebar;
    },

    closeSidebar() {
      this.isShowSidebar = false;
    },

    storeSelectHandler(value: string) {
      if (value === 'extra') {
        this.$router.push({ name: 'store-create' });
        return;
      }
      this.resetStoreExclude({
        modules: ['auth', 'app', 'dictionaries'],
        states: {
          stores: ['storeOptions'],
        },
      });
      this.setStoreSelected(value);
      this.setShouldWalletsBeLoaded(true);
      this.$router.push({ name: 'dashboard', params: { store_id: value } });
    },

    async logout() {
      await this.logoutUser();
    },

    ...mapMutations('stores', ['setStoreSelected']),
    ...mapMutations('wallets', ['setShouldWalletsBeLoaded']),
    ...mapActions('auth', ['logoutUser']),
    ...mapMutations(['resetStoreExclude']),
  },
});
