
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';
import UiSkeletonBox from '@/components/ui/UiSkeletonBox.vue';

export default defineComponent({
  props: {
    storeSelected: {
      type: String,
    },
  },

  components: {
    UiCopyToClipboard,
    UiSkeletonBox,
  },

  data() {
    return {
      tableColumns: [
        {
          label: 'Date',
          field: 'date',
          textAlign: 'text-left',
        },
        {
          label: 'Transaction Hash',
          field: 'tx',
          textAlign: 'text-left',
        },
        {
          label: 'Amount',
          field: 'amount',
          textAlign: 'text-center',
        },
        {
          label: 'Currency',
          field: 'currencyId',
          textAlign: 'text-right',
        },
      ],
      loading: false,
    };
  },

  computed: {
    ...mapState('dashboard', {
      recentTransactions: 'recentTransactions',
      isRecentTransactionsLoaded: 'isRecentTransactionsLoaded',
    }),
  },

  watch: {
    storeSelected() {
      this.loading = true;
      this.loadRecentTransactionsData();
    },
  },

  created() {
    if (!this.isRecentTransactionsLoaded) {
      this.loading = true;
    }
    this.loadRecentTransactionsData();
  },

  methods: {
    async loadRecentTransactionsData() {
      try {
        await this.loadRecentTransactions();
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    ...mapActions('dashboard', ['loadRecentTransactions']),
  },
});
