import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-381fb980"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-6" }
const _hoisted_2 = { class: "flex items-center justify-between gap-2" }
const _hoisted_3 = { class: "text-3xl font-600" }
const _hoisted_4 = { class: "search-group" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = {
  class: "button button-search",
  type: "submit"
}
const _hoisted_7 = { class: "relative table-h-scroll" }
const _hoisted_8 = {
  key: 0,
  class: "w-full bg-white rounded-sm shadow-sm mt-10 mb-5 border"
}
const _hoisted_9 = { class: "border-b" }
const _hoisted_10 = {
  key: 0,
  class: "flex items-center gap-2"
}
const _hoisted_11 = { class: "text-lg" }
const _hoisted_12 = {
  key: 1,
  class: "flex items-center justify-center gap-2"
}
const _hoisted_13 = { class: "text-lg" }
const _hoisted_14 = {
  key: 2,
  class: "text-lg"
}
const _hoisted_15 = {
  key: 0,
  class: "break-all"
}
const _hoisted_16 = {
  key: 1,
  class: "break-all"
}
const _hoisted_17 = {
  key: 3,
  class: "table-actions"
}
const _hoisted_18 = {
  key: 0,
  class: "flex items-center justify-between"
}
const _hoisted_19 = { class: "flex items-center gap-2 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ui_loading = _resolveComponent("ui-loading")!
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_ui_pagination = _resolveComponent("ui-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('Invoices')), 1),
        _createVNode(_component_router_link, {
          class: "button button-primary px-6 py-3 flex items-center gap-2",
          to: { name: 'invoice-create', params: { store_id: _ctx.getStoreSelected } }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_fa_icon, { icon: "plus" }),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('Create Invoice')), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.searchInvoice && _ctx.searchInvoice(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_4, [
        _withDirectives(_createElementVNode("input", {
          class: "input input-search",
          type: "text",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
          placeholder: _ctx.$t('Search by Order Id / Invoice Id')
        }, null, 8, _hoisted_5), [
          [_vModelText, _ctx.searchValue]
        ]),
        _createElementVNode("button", _hoisted_6, [
          _createVNode(_component_fa_icon, { icon: "magnifying-glass" }),
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('Search')), 1)
        ])
      ])
    ], 32),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.invoices.length)
        ? (_openBlock(), _createElementBlock("table", _hoisted_8, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, i) => {
                  return (_openBlock(), _createElementBlock("th", {
                    key: i,
                    scope: "col",
                    class: _normalizeClass(["p-4 align-top", column.textAlign])
                  }, [
                    (column.field === 'createdAt')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createElementVNode("span", _hoisted_11, _toDisplayString(column.label), 1),
                          (_ctx.sortDirection === 'desc' || _ctx.sortField === 'amount')
                            ? (_openBlock(), _createBlock(_component_fa_icon, {
                                key: 0,
                                icon: "arrow-down",
                                size: "sm",
                                class: _normalizeClass(["opacity-55 cursor-pointer", { 'text-sky-500': _ctx.sortActive && _ctx.sortField === 'created_at' }]),
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.sortData('created_at')))
                              }, null, 8, ["class"]))
                            : _createCommentVNode("", true),
                          (_ctx.sortDirection === 'asc' && _ctx.sortField === 'created_at')
                            ? (_openBlock(), _createBlock(_component_fa_icon, {
                                key: 1,
                                icon: "arrow-up",
                                size: "sm",
                                class: _normalizeClass(["opacity-55 cursor-pointer", { 'text-sky-500': _ctx.sortActive }]),
                                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sortData('created_at')))
                              }, null, 8, ["class"]))
                            : _createCommentVNode("", true)
                        ]))
                      : (column.field === 'amount')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("span", _hoisted_13, _toDisplayString(column.label), 1),
                            (_ctx.sortDirection === 'desc' || _ctx.sortField === 'created_at')
                              ? (_openBlock(), _createBlock(_component_fa_icon, {
                                  key: 0,
                                  icon: "arrow-down",
                                  size: "sm",
                                  class: _normalizeClass(["opacity-55 cursor-pointer", { 'text-sky-500': _ctx.sortActive && _ctx.sortField === 'amount' }]),
                                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.sortData('amount')))
                                }, null, 8, ["class"]))
                              : _createCommentVNode("", true),
                            (_ctx.sortDirection === 'asc' && _ctx.sortField === 'amount')
                              ? (_openBlock(), _createBlock(_component_fa_icon, {
                                  key: 1,
                                  icon: "arrow-up",
                                  size: "sm",
                                  class: _normalizeClass(["opacity-55 cursor-pointer", { 'text-sky-500': _ctx.sortActive }]),
                                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.sortData('amount')))
                                }, null, 8, ["class"]))
                              : _createCommentVNode("", true)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_14, _toDisplayString(column.label), 1))
                  ], 2))
                }), 128))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invoices, (item, i) => {
                return (_openBlock(), _createElementBlock("tr", { key: i }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, j) => {
                    return (_openBlock(), _createElementBlock("td", {
                      key: j,
                      class: _normalizeClass(["p-4", column.textAlign])
                    }, [
                      (column.field === 'orderId')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(item.orderId), 1))
                        : (column.field === 'id')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(item.id), 1))
                          : (column.field === 'amount')
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _createTextVNode(_toDisplayString(item.amount) + " (" + _toDisplayString(item.currency) + ") ", 1)
                              ], 64))
                            : (column.field === 'actions')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                  (
                          item.status === 'waiting'
                          || item.status ==='partially_paid'
                          || item.status === 'waiting_confirmations'
                        )
                                    ? (_openBlock(), _createBlock(_component_router_link, {
                                        key: 0,
                                        to: { name: 'invoice', params: { invoice_id: item.id } },
                                        class: "cursor-pointer text-sky-500 hover:text-sky-600"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.$t('Checkout')), 1)
                                        ]),
                                        _: 2
                                      }, 1032, ["to"]))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_router_link, {
                                    class: "cursor-pointer text-sky-500 hover:text-sky-600",
                                    to: { name: 'invoice-details', params: { invoice_id: item.id } }
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('Details')), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["to"])
                                ]))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                  _createTextVNode(_toDisplayString(item[column.field]), 1)
                                ], 64))
                    ], 2))
                  }), 128))
                ]))
              }), 128))
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_ui_loading, {
        class: _normalizeClass({'mt-10': !_ctx.searchValue && !_ctx.invoices.length}),
        loading: _ctx.loading
      }, null, 8, ["class", "loading"])
    ]),
    (_ctx.invoices.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('Show')), 1),
            _createVNode(_component_ui_select, {
              class: "max-w-90px h-38px",
              modelValue: _ctx.perPage,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.perPage) = $event)),
              options: _ctx.perPageOptions,
              onChange: _ctx.perPageSelectHandler
            }, null, 8, ["modelValue", "options", "onChange"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('Records')), 1)
          ]),
          _createVNode(_component_ui_pagination, {
            current: _ctx.currentPage,
            total: _ctx.pagination.total,
            "per-page": _ctx.paginationPerPage,
            onPageChange: _ctx.pageChangeHandler
          }, null, 8, ["current", "total", "per-page", "onPageChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}