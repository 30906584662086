import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "bg-white rounded-sm shadow-sm border p-6"
}
const _hoisted_2 = { class: "text-2xl font-600 mb-4" }
const _hoisted_3 = {
  key: 0,
  class: "table-h-scroll"
}
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "border-b" }
const _hoisted_6 = { class: "text-lg" }
const _hoisted_7 = {
  key: 0,
  class: "break-all flex items-center gap-2"
}
const _hoisted_8 = {
  key: 1,
  class: "opacity-55"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_skeleton_box = _resolveComponent("ui-skeleton-box")!
  const _component_ui_copy_to_clipboard = _resolveComponent("ui-copy-to-clipboard")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_ui_skeleton_box, {
        key: 0,
        width: "100%",
        height: "122px"
      }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Recent Transactions')), 1),
        (_ctx.recentTransactions.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("table", _hoisted_4, [
                _createElementVNode("thead", null, [
                  _createElementVNode("tr", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, i) => {
                      return (_openBlock(), _createElementBlock("th", {
                        key: i,
                        scope: "col",
                        class: _normalizeClass(["py-4 pr-3 align-top last:pr-0", column.textAlign])
                      }, [
                        _createElementVNode("div", _hoisted_6, _toDisplayString(column.label), 1)
                      ], 2))
                    }), 128))
                  ])
                ]),
                _createElementVNode("tbody", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recentTransactions, (item, i) => {
                    return (_openBlock(), _createElementBlock("tr", { key: i }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, j) => {
                        return (_openBlock(), _createElementBlock("td", {
                          key: j,
                          class: _normalizeClass(["py-4 pr-3 last:pr-0", column.textAlign])
                        }, [
                          (column.field === 'tx')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createElementVNode("span", null, _toDisplayString(`${item.tx.slice(0, 7)}...${item.tx.slice(-5)}`), 1),
                                _createVNode(_component_ui_copy_to_clipboard, {
                                  "text-to-copy": item.tx
                                }, null, 8, ["text-to-copy"])
                              ]))
                            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createTextVNode(_toDisplayString(item[column.field]), 1)
                              ], 64))
                        ], 2))
                      }), 128))
                    ]))
                  }), 128))
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('There are no recent transactions.')), 1))
      ]))
}