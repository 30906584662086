import { Plugin } from 'vue';
import store from '@/store';
import router from '@/router';

const initial: Plugin = async () => {
  store.commit('wallets/setShouldWalletsBeLoaded', true);

  await router.isReady();
  const routeParam = router.currentRoute.value.params.store_id;

  if (store.getters['auth/isAuth']) {
    if (!routeParam) {
      await store.dispatch('stores/loadStores');
    }
    if (!store.state.stores.isStoreLoading) {
      await store.dispatch('stores/loadStoreById');
    }
  }
  if (!store.state.dictionaries.isDictionariesLoading) {
    await store.dispatch('dictionaries/loadDictionaries');
  }
};

export default initial;
