
import { defineComponent } from 'vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiLoading from '@/components/ui/UiLoading.vue';

export default defineComponent({
  components: {
    UiPagination,
    UiSelect,
    UiLoading,
  },

  data() {
    return {
      searchValue: '',
      loading: false,
      tableColumns: [
        {
          label: 'Date',
          field: 'createdAt',
          textAlign: 'text-left',
        },
        {
          label: 'Order Id',
          field: 'orderId',
          textAlign: 'text-left',
        },
        {
          label: 'Invoice Id',
          field: 'id',
          textAlign: 'text-left',
        },
        {
          label: 'Status',
          field: 'status',
          textAlign: 'text-center',
        },
        {
          label: 'Amount',
          field: 'amount',
          textAlign: 'text-center',
        },
        {
          label: 'Actions',
          field: 'actions',
          textAlign: 'text-right',
        },
      ],
      currentPage: 1,
      perPage: 25,
      perPageOptions: [
        {
          title: '25',
          value: '25',
        },
        {
          title: '50',
          value: '50',
        },
        {
          title: '100',
          value: '100',
        },
        {
          title: '250',
          value: '250',
        },
      ],
      sortField: '',
      sortDirection: 'desc',
      sortActive: false,
      sortStep: 1,
    };
  },

  computed: {
    paginationPerPage(): number {
      return Number(this.perPage);
    },

    ...mapState('invoices', {
      invoices: 'invoices',
      pagination: 'pagination',
    }),
    ...mapGetters('stores', {
      getStoreSelected: 'getStoreSelected',
    }),
  },

  created() {
    this.loadInvoicesData();
  },

  methods: {
    async searchInvoice() {
      this.currentPage = 1;
      this.sortField = '';
      this.sortDirection = 'desc';
      await this.$router.replace({
        name: this.$route.name as string,
        query: this.searchValue ? { query: this.searchValue } : {},
      });
      this.loading = true;
      await this.loadInvoicesData();
    },

    perPageSelectHandler() {
      this.loading = true;
      this.loadInvoicesData();
    },

    pageChangeHandler(page: number) {
      this.currentPage = page;
      this.loading = true;
      this.loadInvoicesData();
    },

    sortData(sortField: string) {
      this.sortField = sortField;
      if (this.sortStep === 1) {
        this.sortActive = true;
        this.sortDirection = 'desc';
        this.sortStep = 2;
        return;
      }
      if (this.sortStep === 2) {
        this.sortActive = true;
        this.sortDirection = 'asc';
        this.loading = true;
        this.loadInvoicesData();
        this.sortStep = 3;
        return;
      }
      if (this.sortStep === 3) {
        this.sortActive = false;
        this.sortDirection = 'desc';
        this.loading = true;
        this.loadInvoicesData();
        this.sortStep = 1;
        this.sortField = '';
      }
    },

    async loadInvoicesData() {
      const query = this.$route.query.query as string;
      this.searchValue = query;
      try {
        const params: Record<string, any> = {
          page: this.currentPage,
          perPage: this.perPage,
          query: query ? this.searchValue : '',
          sortDirection: this.sortDirection,
        };

        if (this.sortField) {
          params.sortField = this.sortField;
        }

        await this.loadInvoices(params);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    ...mapActions('invoices', ['loadInvoices']),
  },
});
