
import { defineComponent } from 'vue';
import { mapState, mapActions } from 'vuex';
import UiCopyToClipboard from '@/components/ui/UiCopyToClipboard.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiPagination from '@/components/ui/UiPagination.vue';

export default defineComponent({
  components: {
    UiCopyToClipboard,
    UiLoading,
    UiSelect,
    UiPagination,
  },

  data() {
    return {
      loading: false,
      tableColumns: [
        {
          label: 'Date',
          field: 'createdAt',
          textAlign: 'text-left',
        },
        {
          label: 'Address',
          field: 'address',
          textAlign: 'text-left',
        },
        {
          label: 'Tx Id',
          field: 'txId',
          textAlign: 'text-left',
        },
        {
          label: 'Currency',
          field: 'currency',
          textAlign: 'text-center',
        },
        {
          label: 'Amount',
          field: 'amount',
          textAlign: 'text-center',
        },
        {
          label: 'Type',
          field: 'isManual',
          textAlign: 'text-right',
        },
      ],
      currentPage: 1,
      perPage: 25,
      perPageOptions: [
        {
          title: '25',
          value: '25',
        },
        {
          title: '50',
          value: '50',
        },
        {
          title: '100',
          value: '100',
        },
        {
          title: '250',
          value: '250',
        },
      ],
    };
  },

  computed: {
    paginationPerPage(): number {
      return Number(this.perPage);
    },

    ...mapState('wallets', {
      wallets: 'wallets',
      completedWithdrawals: 'completedWithdrawals',
      pagination: 'completedWithdrawalsPagination',
    }),
  },

  created() {
    this.loadWithdrawalsData();
  },

  methods: {
    async loadWithdrawalsData() {
      try {
        const params: Record<string, any> = {
          page: this.currentPage,
          perPage: this.perPage,
        };
        await this.loadCompletedWithdrawals(params);
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },

    perPageSelectHandler() {
      this.loading = true;
      this.loadWithdrawalsData();
    },

    pageChangeHandler(page: number) {
      this.currentPage = page;
      this.loading = true;
      this.loadWithdrawalsData();
    },

    ...mapActions('wallets', ['loadCompletedWithdrawals']),
  },
});
