
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

export default defineComponent({
  components: {
    UiButtonLoading,
  },

  data() {
    return {
      email: '',
      password: '',
      confirmPassword: '',
      buttonLoading: false,
    };
  },

  methods: {
    async register() {
      try {
        this.buttonLoading = true;
        await this.registerUser({
          email: this.email,
          password: this.password,
          password_confirmation: this.confirmPassword,
        });

        await this.loginUser({
          email: this.email,
          password: this.password,
        });

        await this.loadStores();
        await this.redirectAfterLoadStores();
        this.buttonLoading = false;
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    ...mapActions('auth', ['registerUser', 'loginUser']),
    ...mapActions('stores', ['loadStores', 'redirectAfterLoadStores']),
  },
});
