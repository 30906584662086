
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

export default defineComponent({
  components: {
    UiButtonLoading,
  },

  data() {
    return {
      email: '',
      password: '',
      buttonLoading: false,
      needOtp: false,
      otpCode: '',
    };
  },

  methods: {
    async login() {
      try {
        this.buttonLoading = true;

        await this.loginUser({
          email: this.email,
          password: this.password,
        });

        if (this.isOtpPassed()) {
          this.needOtp = false;

          await this.loadStores();
          await this.redirectAfterLoadStores();
        } else {
          this.needOtp = true;
        }

        this.buttonLoading = false;
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    async otp() {
      try {
        this.buttonLoading = true;

        await this.loginUserWithOTP({
          code: this.otpCode,
        });

        this.needOtp = false;

        await this.loadStores();
        await this.redirectAfterLoadStores();

        this.buttonLoading = false;
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    ...mapActions('auth', ['loginUser', 'loginUserWithOTP']),
    ...mapActions('stores', ['loadStores', 'redirectAfterLoadStores']),
    ...mapGetters('auth', ['isOtpPassed']),
  },
});
