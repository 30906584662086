
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';
import VueQrcode from 'vue-qrcode';
import base32Encode from 'base32-encode';

export default defineComponent({
  components: {
    UiButtonLoading,
    VueQrcode,
  },

  data() {
    return {
      buttonLoading: false,
      secretCode: crypto.getRandomValues(new Uint8Array(8)),
      answer: '',
    };
  },

  async mounted() {
    await this.loadAccount();
  },

  computed: {
    qrCodeValue(): string {
      const email = this.getAccount().email;

      return `otpauth://totp/1IF Pay:${email}?secret=${this.base32Value}&issuer=1IF Pay`;
    },
    base32Value(): string {
      return base32Encode(this.secretCode, 'RFC3548');
    },
  },

  methods: {
    resetForm() {
      this.buttonLoading = false;
      this.secretCode = crypto.getRandomValues(new Uint8Array(8));
      this.answer = '';
    },

    async enableAccountOTP() {
      try {
        this.buttonLoading = true;
        await this.enableOTP({
          secret: this.base32Value,
          code: this.answer,
        });
        this.buttonLoading = false;
        this.$toast.success(this.$t('OTP successfully enabled'));
        this.resetForm();
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    async disableAccountOTP() {
      try {
        this.buttonLoading = true;
        await this.disableOTP({
          code: this.answer,
        });
        this.buttonLoading = false;
        this.$toast.success(this.$t('OTP successfully disabled'));
        this.resetForm();
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    ...mapActions('account', ['loadAccount', 'enableOTP', 'disableOTP']),
    ...mapGetters('account', ['getAccount', 'isOTPEnabled']),
  },
});
