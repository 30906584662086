import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23aecf82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-wrapper" }
const _hoisted_2 = { class: "flex justify-end mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          "aria-hidden": "true",
          class: "modal-backdrop",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeHandler && _ctx.closeHandler(...args)))
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", {
              class: "modal-container",
              role: "dialog",
              "aria-modal": "true",
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_fa_icon, {
                  icon: "xmark",
                  class: "icon-close",
                  size: "lg",
                  onClick: _ctx.closeHandler
                }, null, 8, ["onClick"])
              ]),
              _createElementVNode("div", null, [
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ])
          ])
        ], 512), [
          [_vShow, _ctx.modelValue]
        ])
      ]),
      _: 3
    })
  ]))
}