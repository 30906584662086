import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02a4d4e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "account-change-password" }
const _hoisted_2 = { class: "form-inputs" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { for: "currentPassword" }
const _hoisted_5 = { class: "form-group" }
const _hoisted_6 = { for: "password" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { for: "confirmPassword" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      class: "form",
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changePass && _ctx.changePass(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('Current password')), 1),
          _withDirectives(_createElementVNode("input", {
            required: "",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPassword) = $event)),
            id: "currentPassword",
            class: "input my-2 w-full",
            type: "password",
            "aria-label": "password",
            name: "password"
          }, null, 512), [
            [_vModelText, _ctx.currentPassword]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('New password')), 1),
          _withDirectives(_createElementVNode("input", {
            required: "",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
            class: "input my-2 w-full",
            type: "password",
            "aria-label": "password",
            name: "password"
          }, null, 512), [
            [_vModelText, _ctx.password]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('Confirm new password')), 1),
          _withDirectives(_createElementVNode("input", {
            required: "",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmPassword) = $event)),
            id: "confirmPassword",
            class: "input my-2 w-full",
            type: "password",
            "aria-label": "password",
            name: "password"
          }, null, 512), [
            [_vModelText, _ctx.confirmPassword]
          ])
        ])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_ui_button_loading, {
          class: "button button-primary my-4 p-2",
          type: "submit",
          loading: _ctx.buttonLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Change password')), 1)
          ]),
          _: 1
        }, 8, ["loading"])
      ])
    ], 32)
  ]))
}