import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white rounded-sm shadow-sm border p-6 mb-5 relative"
}
const _hoisted_2 = { class: "text-2xl font-600 mb-4" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = { class: "border-b" }
const _hoisted_5 = { class: "text-lg" }
const _hoisted_6 = {
  key: 0,
  class: "break-all flex items-center gap-2"
}
const _hoisted_7 = {
  key: 1,
  class: "break-all flex items-center gap-2"
}
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 1,
  class: "flex items-center justify-between"
}
const _hoisted_12 = { class: "flex items-center gap-2 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_copy_to_clipboard = _resolveComponent("ui-copy-to-clipboard")!
  const _component_ui_loading = _resolveComponent("ui-loading")!
  const _component_ui_select = _resolveComponent("ui-select")!
  const _component_ui_pagination = _resolveComponent("ui-pagination")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.completedWithdrawals.length && _ctx.wallets.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('Completed withdrawals')), 1),
          _createElementVNode("div", null, [
            _createElementVNode("table", _hoisted_3, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, i) => {
                    return (_openBlock(), _createElementBlock("th", {
                      key: i,
                      scope: "col",
                      class: _normalizeClass(["py-4 pr-3 align-top last:pr-0 <laptop-lg:py-2", column.textAlign])
                    }, [
                      _createElementVNode("div", _hoisted_5, _toDisplayString(column.label), 1)
                    ], 2))
                  }), 128))
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.completedWithdrawals, (item, i) => {
                  return (_openBlock(), _createElementBlock("tr", { key: i }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, j) => {
                      return (_openBlock(), _createElementBlock("td", {
                        key: j,
                        class: _normalizeClass(["py-4 pr-3 last:pr-0 <laptop-lg:p-2", column.textAlign])
                      }, [
                        (column.field === 'address')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                              _createElementVNode("span", null, _toDisplayString(item.address), 1),
                              (item.address)
                                ? (_openBlock(), _createBlock(_component_ui_copy_to_clipboard, {
                                    key: 0,
                                    "text-to-copy": item.address
                                  }, null, 8, ["text-to-copy"]))
                                : _createCommentVNode("", true)
                            ]))
                          : (column.field === 'txId')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createElementVNode("span", null, _toDisplayString(`${item.txId.slice(0, 7)}...${item.txId.slice(-5)}`), 1),
                                (item.txId)
                                  ? (_openBlock(), _createBlock(_component_ui_copy_to_clipboard, {
                                      key: 0,
                                      "text-to-copy": item.txId
                                    }, null, 8, ["text-to-copy"]))
                                  : _createCommentVNode("", true)
                              ]))
                            : (column.field === 'currency')
                              ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(item.currency.split('.')[0]), 1))
                              : (column.field === 'isManual')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                    (item[column.field])
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t('Manual')), 1))
                                      : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('Automatic')), 1))
                                  ], 64))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                    _createTextVNode(_toDisplayString(item[column.field]), 1)
                                  ], 64))
                      ], 2))
                    }), 128))
                  ]))
                }), 128))
              ])
            ])
          ]),
          _createVNode(_component_ui_loading, { loading: _ctx.loading }, null, 8, ["loading"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.completedWithdrawals.length && _ctx.wallets.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('Show')), 1),
            _createVNode(_component_ui_select, {
              class: "max-w-90px h-38px",
              modelValue: _ctx.perPage,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.perPage) = $event)),
              options: _ctx.perPageOptions,
              onChange: _ctx.perPageSelectHandler
            }, null, 8, ["modelValue", "options", "onChange"]),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('Records')), 1)
          ]),
          _createVNode(_component_ui_pagination, {
            current: _ctx.currentPage,
            total: _ctx.pagination.total,
            "per-page": _ctx.paginationPerPage,
            onPageChange: _ctx.pageChangeHandler
          }, null, 8, ["current", "total", "per-page", "onPageChange"])
        ]))
      : _createCommentVNode("", true)
  ]))
}