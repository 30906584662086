import { createI18n } from 'vue-i18n';
import store from '@/store';

import enUS from './locales/en.json';
import ruRu from './locales/ru.json';

// Type-define 'en-US' as the master schema for the resource
type MessageSchema = typeof enUS;

const i18n = createI18n<[MessageSchema], 'en-US' | 'ru-RU'>({
  locale: store.state.app.locale,
  fallbackLocale: 'en-US',
  legacy: false,
  globalInjection: true,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  messages: {
    'en-US': enUS,
    'ru-RU': ruRu,
  },
});

export default i18n;
