
import { defineComponent, PropType } from 'vue';
import { SelectOptionType } from '@/components/types';

export default defineComponent({
  props: {
    options: {
      type: Array as PropType<SelectOptionType[]>,
      required: true,
    },
    id: {
      type: [String, Number],
      required: false,
    },
    modelValue: {
      type: [String, Number],
    },
  },

  emits: ['update:modelValue', 'change'],

  methods: {
    selectHandler(e: Event) {
      const value = (e.target as HTMLSelectElement).value;
      this.$emit('update:modelValue', value);
      this.$emit('change');
    },
  },
});
