import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white rounded-sm shadow-sm border p-6"
}
const _hoisted_2 = { class: "text-2xl font-600 mb-4" }
const _hoisted_3 = { class: "table-h-scroll" }
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "border-b" }
const _hoisted_6 = { class: "text-lg" }
const _hoisted_7 = {
  key: 0,
  class: "break-all flex items-center gap-2"
}
const _hoisted_8 = {
  key: 1,
  class: "uppercase"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_copy_to_clipboard = _resolveComponent("ui-copy-to-clipboard")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.wallets.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('User wallets for withdrawals')), 1),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("table", _hoisted_4, [
              _createElementVNode("thead", null, [
                _createElementVNode("tr", _hoisted_5, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, i) => {
                    return (_openBlock(), _createElementBlock("th", {
                      key: i,
                      scope: "col",
                      class: _normalizeClass(["py-4 pr-3 align-top last:pr-0", column.textAlign])
                    }, [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(column.label), 1)
                    ], 2))
                  }), 128))
                ])
              ]),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.wallets, (item, i) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: i,
                    class: "hover:bg-white"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableColumns, (column, j) => {
                      return (_openBlock(), _createElementBlock("td", {
                        key: j,
                        class: _normalizeClass(["py-4 pr-3 last:pr-0", column.textAlign])
                      }, [
                        (column.field === 'address')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                              _createElementVNode("span", null, _toDisplayString(item.address), 1),
                              (item.address)
                                ? (_openBlock(), _createBlock(_component_ui_copy_to_clipboard, {
                                    key: 0,
                                    "text-to-copy": item.address
                                  }, null, 8, ["text-to-copy"]))
                                : _createCommentVNode("", true)
                            ]))
                          : (column.field === 'blockchain')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(item.blockchain), 1))
                            : (column.field === 'balance')
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 2,
                                  class: _normalizeClass({'h-50px flex flex-col justify-between': item.balance.length > 1})
                                }, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.balance, (balance, k) => {
                                    return (_openBlock(), _createElementBlock("div", { key: k }, [
                                      _createElementVNode("span", null, _toDisplayString(balance.currencyId.split('.')[0]) + ": " + _toDisplayString(balance.balance) + " (" + _toDisplayString(balance.balanceUsd) + " USD) ", 1)
                                    ]))
                                  }), 128))
                                ], 2))
                              : (column.field === 'actions')
                                ? (_openBlock(), _createBlock(_component_router_link, {
                                    key: 3,
                                    class: "cursor-pointer text-sky-500 hover:text-sky-600",
                                    to: {
                          name: 'wallets-withdrawals-settings',
                          params: {
                            store_id: _ctx.getStoreSelected,
                            wallet_id: item.id,
                          }
                        }
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('Settings')), 1)
                                    ]),
                                    _: 2
                                  }, 1032, ["to"]))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                                    _createTextVNode(_toDisplayString(item[column.field]), 1)
                                  ], 64))
                      ], 2))
                    }), 128))
                  ]))
                }), 128))
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}