import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-light w-full min-h-100vh flex" }
const _hoisted_2 = { class: "m-auto w-full max-w-md overflow-hidden bg-white rounded-md shadow-md" }
const _hoisted_3 = { class: "px-6 py-4" }
const _hoisted_4 = { class: "text-3xl font-bold text-center text-gray-700" }
const _hoisted_5 = { class: "mt-1 mb-2 text-xl font-medium text-center" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "flex items-center justify-center py-4 text-center bg-gray-50" }
const _hoisted_10 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('Merchant')), 1),
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('Create your account')), 1),
        _createElementVNode("form", {
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.register && _ctx.register(...args)), ["prevent"]))
        }, [
          _withDirectives(_createElementVNode("input", {
            required: "",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
            class: "input my-2 w-full",
            type: "email",
            placeholder: _ctx.$t('Email'),
            "aria-label": "email",
            name: "email"
          }, null, 8, _hoisted_6), [
            [_vModelText, _ctx.email]
          ]),
          _withDirectives(_createElementVNode("input", {
            required: "",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
            class: "input my-2 w-full",
            type: "password",
            placeholder: _ctx.$t('Password'),
            "aria-label": "password",
            name: "password"
          }, null, 8, _hoisted_7), [
            [_vModelText, _ctx.password]
          ]),
          _withDirectives(_createElementVNode("input", {
            required: "",
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.confirmPassword) = $event)),
            class: "input my-2 w-full",
            type: "password",
            placeholder: _ctx.$t('Confirm password'),
            "aria-label": "password",
            name: "password"
          }, null, 8, _hoisted_8), [
            [_vModelText, _ctx.confirmPassword]
          ]),
          _createVNode(_component_ui_button_loading, {
            class: "button button-primary w-full my-4 p-2",
            type: "submit",
            loading: _ctx.buttonLoading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('Create account')), 1)
            ]),
            _: 1
          }, 8, ["loading"])
        ], 32)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('Already using Merchant?')), 1),
        _createVNode(_component_router_link, {
          to: "/login",
          class: "mx-2 text-sm font-bold text-sky-500 hover:underline cursor-pointer"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Sign in here')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}