import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d427b624"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-tooltip"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", {
    "aria-hidden": "true",
    class: _normalizeClass(["copy", {'hoverhint': _ctx.hoverHint}]),
    "data-tooltip": _ctx.hoverText,
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args)))
  }, [
    (_ctx.check)
      ? (_openBlock(), _createBlock(_component_fa_icon, {
          key: 0,
          class: "w-18px",
          icon: ['far', 'circle-check']
        }))
      : (_openBlock(), _createBlock(_component_fa_icon, {
          key: 1,
          class: "w-18px",
          icon: ['far', 'copy']
        }))
  ], 10, _hoisted_1))
}