import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-gray-light w-full min-h-100vh flex" }
const _hoisted_2 = { class: "m-auto w-full max-w-md overflow-hidden bg-white rounded-md shadow-md text-center" }
const _hoisted_3 = { class: "px-6 py-4" }
const _hoisted_4 = { class: "text-3xl font-bold text-gray-700" }
const _hoisted_5 = { class: "mt-1 text-xl font-medium text-center" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "mt-1 mb-2 text-gray-500" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "mt-1 mb-2 text-gray-500" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = { class: "flex items-center justify-center py-4 bg-gray-50" }
const _hoisted_14 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ui_button_loading = _resolveComponent("ui-button-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t('1IF Pay')), 1),
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.$t('Welcome Back')), 1),
        (!_ctx.needOtp)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t('Login or create account')), 1),
              _createElementVNode("form", {
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
              }, [
                _withDirectives(_createElementVNode("input", {
                  required: "",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                  class: "input my-2 w-full",
                  type: "email",
                  placeholder: _ctx.$t('Email'),
                  "aria-label": "email",
                  name: "email"
                }, null, 8, _hoisted_8), [
                  [_vModelText, _ctx.email]
                ]),
                _withDirectives(_createElementVNode("input", {
                  required: "",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
                  class: "input my-2 w-full",
                  type: "password",
                  placeholder: _ctx.$t('Password'),
                  "aria-label": "password",
                  name: "password"
                }, null, 8, _hoisted_9), [
                  [_vModelText, _ctx.password]
                ]),
                _createVNode(_component_router_link, {
                  to: "/lost-password",
                  class: "mt-2 text-sm cursor-pointer hover:text-gray-500"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Forgot password?')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ui_button_loading, {
                  class: "button button-primary w-full my-4 p-2",
                  type: "submit",
                  loading: _ctx.buttonLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Login')), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ], 32)
            ]))
          : _createCommentVNode("", true),
        (_ctx.needOtp)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('Enter OTP code')), 1),
              _createElementVNode("form", {
                onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.otp && _ctx.otp(...args)), ["prevent"]))
              }, [
                _withDirectives(_createElementVNode("input", {
                  required: "",
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.otpCode) = $event)),
                  class: "input my-2 w-full",
                  type: "text",
                  placeholder: _ctx.$t('OTP Code'),
                  "aria-label": "otpCode",
                  name: "otpCode"
                }, null, 8, _hoisted_12), [
                  [_vModelText, _ctx.otpCode]
                ]),
                _createVNode(_component_ui_button_loading, {
                  class: "button button-primary w-full my-4 p-2",
                  type: "submit",
                  loading: _ctx.buttonLoading
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('Check OTP')), 1)
                  ]),
                  _: 1
                }, 8, ["loading"])
              ], 32)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('Don\'t have an account?')), 1),
        _createVNode(_component_router_link, {
          to: "/register",
          class: "mx-2 text-sm font-bold text-sky-500 hover:underline cursor-pointer"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Register')), 1)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}