import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a7c5a0d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["loading", _ctx.bgColor])
        }, [
          _createVNode(_component_fa_icon, {
            icon: "spinner",
            size: _ctx.size,
            class: _normalizeClass(["animate-spin text-gray-400", _ctx.color])
          }, null, 8, ["size", "class"])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}