
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

export default defineComponent({
  components: {
    UiSelect,
    UiButtonLoading,
  },

  data() {
    return {
      orderId: '',
      amount: '',
      currencySelect: '',
      itemDescription: '',
      returnUrl: '',
      buttonLoading: false,
    };
  },

  computed: {
    ...mapGetters('dictionaries', {
      fiatCurrencies: 'fiatCurrencies',
    }),
  },

  watch: {
    fiatCurrencies() {
      this.syncDataFromVuex();
    },
  },

  mounted() {
    this.syncDataFromVuex();
  },

  methods: {
    async createInvoiceHandler() {
      try {
        this.buttonLoading = true;
        const data = await this.createInvoice({
          orderId: this.orderId,
          amount: this.amount || null,
          currencyCode: this.currencySelect,
          description: this.itemDescription,
          returnUrl: this.returnUrl,
        });
        this.buttonLoading = false;
        this.$toast.success(this.$t('Invoice successfully created'));
        this.$router.push({
          name: 'invoice-details',
          params: { invoice_id: data.id },
        });
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    syncDataFromVuex() {
      this.currencySelect = this.fiatCurrencies[0]?.value;
    },

    ...mapActions('invoices', ['createInvoice']),
  },
});
