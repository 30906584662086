
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import UiButtonLoading from '@/components/ui/UiButtonLoading.vue';

export default defineComponent({
  components: {
    UiButtonLoading,
  },

  data() {
    return {
      currentPassword: '',
      password: '',
      confirmPassword: '',
      buttonLoading: false,
    };
  },

  methods: {
    resetForm() {
      this.currentPassword = '';
      this.password = '';
      this.confirmPassword = '';
    },

    async changePass() {
      try {
        this.buttonLoading = true;
        await this.changePassword({
          current_password: this.currentPassword,
          password: this.password,
          password_confirmation: this.confirmPassword,
        });
        this.buttonLoading = false;
        this.$toast.success(this.$t('Password successfully updated'));
        this.resetForm();
      } catch (e) {
        this.buttonLoading = false;
      }
    },

    ...mapActions('account', ['changePassword']),
  },
});
